import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../ducks/reducer';
import { InnloggingsstatusState } from '../ducks/innloggingsstatus-duck';
const { location } = window;

interface OwnProps {
    children: React.ReactElement<any>; // tslint:disable-line:no-any
}

interface StateProps {
    innloggingsstatus: InnloggingsstatusState;
}

type InnloggingProviderProps = OwnProps & StateProps;

function InnloggingProvider({innloggingsstatus, children}: InnloggingProviderProps) {

    const {erInnlogget, harGyldigOidcToken, nivaOidc} = innloggingsstatus;

    if (erInnlogget && !(harGyldigOidcToken && nivaOidc === 4)) {
        location.href = '/veilarbstepup/oidc?url=/';
    }
    return children;
}

const mapStateToProps = (state: AppState): StateProps => ({
    innloggingsstatus: state.innloggingsstatus,
});

export default connect(mapStateToProps)(InnloggingProvider);
