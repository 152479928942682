import * as React from 'react';
import { AppState } from '../../ducks/reducer';
import { connect } from 'react-redux';
import VisibleIf from '../../utils/visible-if';
import { FormattedMessage } from 'react-intl';
import { Normaltekst, Element } from 'nav-frontend-typografi';
import LoggInnModal from '../../resultat/lagring/logg-inn-modal';

interface StateProps {
    harLagretInnevaerendeKartleggingIDatabasen: boolean;
    harGyldigOidcToken: boolean;
}

export type LagreResultaterKnappProps = StateProps;

interface StateType {
    modalIsOpen: boolean;
}

export class LagreResultaterKnapp extends React.Component<LagreResultaterKnappProps> {
    public state: StateType;
    constructor(props: LagreResultaterKnappProps) {
        super(props);
        this.state = {
            modalIsOpen: false
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    render() {
        const { modalIsOpen } = this.state;
        const { harLagretInnevaerendeKartleggingIDatabasen, harGyldigOidcToken } = this.props;

        return (
            <>
                <VisibleIf betingelse={!harLagretInnevaerendeKartleggingIDatabasen && !harGyldigOidcToken}>
                    <>
                        <Element>
                            <FormattedMessage id="resultat-lagreknapp-tekst1"/>
                        </Element>
                        <Normaltekst className="blokk-s">
                            <FormattedMessage id="resultat-lagreknapp-tekst2"/>
                        </Normaltekst>
                        <button
                            className="knapp knapp--hoved knapp--full resultat__logginn-lagre"
                            onClick={() => this.openModal()}
                        >
                            <FormattedMessage id="resultat-lagreknapp"/>
                        </button>
                        <LoggInnModal
                            ingress="resultat-logg-inn-lagringsmelding"
                            onClose={() => this.closeModal()}
                            modalIsOpen={modalIsOpen}
                        />
                    </>
                </VisibleIf>
            </>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    harLagretInnevaerendeKartleggingIDatabasen: state.api.harLagretInnevaerendeKartleggingIDatabasen,
    harGyldigOidcToken: state.innloggingsstatus.harGyldigOidcToken,
});

export default connect(mapStateToProps)(LagreResultaterKnapp);
