import * as React from 'react';
import SporsmalModell from './sporsmal-modell';

interface OwnProps {
    sporsmal: SporsmalModell;
}

const SporsmalBilde = (props: OwnProps) => {
    const { sporsmal } = props;
    const sporsmalImg = require('../ikoner/' + sporsmal.id + '.svg');

    return <img src={sporsmalImg} className="sporsmal__ikon" alt=""/>;
};

export { SporsmalBilde };
