import * as React from 'react';
import { BrukerRaad } from './raad-modell';
import { Innholdstittel, Normaltekst } from 'nav-frontend-typografi';
import { Sidetype } from '../../utils/konstanter';
import LinkMedQueryParams from '../../link-med-query-params';

const bilder = {
    '1-1': require('../../ikoner/resultat/raad/beskriv_kompetansen_din.svg'),
    '2-8': require('../../ikoner/resultat/raad/bruk_nettverket_ditt.svg'),
    '4-2': require('../../ikoner/resultat/raad/forbered_deg_til_intervju.svg'),
    '4-6': require('../../ikoner/resultat/raad/gjor_et_godt_forste_inntrykk.svg'),
    '3-6': require('../../ikoner/resultat/raad/hold_orden_paa_soknaden.svg'),
    '3-2': require('../../ikoner/resultat/raad/lag_en_god_cv.svg'),
    '5-1': require('../../ikoner/resultat/raad/lag_en_plan.svg'),
    '4-5': require('../../ikoner/resultat/raad/ov_paa_intervjusituasjonen.svg'),
    '3-8': require('../../ikoner/resultat/raad/send_apen_soknad.svg'),
    '3-1': require('../../ikoner/resultat/raad/skriv_en_god_soknad.svg'),
    '2-12': require('../../ikoner/resultat/raad/sok_jobb_i_ulike_bransjer.svg'),
    '2-13': require('../../ikoner/resultat/raad/sok_jobb_utenfor_hjemstedet.svg'),
    '3-9': require('../../ikoner/resultat/raad/ta_kontakt_med_en_bedrift.svg'),
};

const backupBilder = {
    0: require('../../ikoner/resultat/raad/raad_1.svg'),
    1: require('../../ikoner/resultat/raad/raad_2.svg'),
    2: require('../../ikoner/resultat/raad/raad_3.svg'),
    3: require('../../ikoner/resultat/raad/raad_4.svg'),
};

export const raadBilde = (id: string, index: number) => bilder[id] || backupBilder[index % 4];

interface OwnProps {
    brukerRaad: BrukerRaad;
    index: number;
}

type RaadVisningProps = OwnProps;

const RaadVisning = (props: RaadVisningProps) => {
    const { brukerRaad, index } = props;
    const raadUrl = '/' + Sidetype.RESULTAT + '/raad/' + brukerRaad.id + '/' + index;
    return (
        <LinkMedQueryParams
            url={raadUrl}
            className="enkelt__raad"
            key={brukerRaad.tittel}
        >
            <div className="raad__tekst__container">
                <Innholdstittel tag="h2" className="raad__heading">
                    {brukerRaad.tittel || 'Tittel'}
                </Innholdstittel>
                <Normaltekst className="raad__ingress">{brukerRaad.ingress}</Normaltekst>
            </div>
            <div className="raad__bilde__container">
                <img
                    src={raadBilde(brukerRaad.id, index)}
                    alt=""
                    className="raad__bilde"
                    role="img"
                />
            </div>
        </LinkMedQueryParams>
    );
}

export default RaadVisning;