import * as React from 'react';
import Spinner from 'nav-frontend-spinner';
import AlertStripe from 'nav-frontend-alertstriper';
import { FormattedMessage } from 'react-intl';

export enum Status {
    OK = 'OK',
    FEILET = 'FEILET',
    PENDING = 'PENDING',
    IKKE_STARTET = 'IKKE_STARTET',
    RELOADING = 'RELOADING',
}

export interface DataElement {
    status: Status;
}

interface DatalasterProps {
    avhengigheter: DataElement[];
    feilmeldingId?: string;
    children: React.ReactElement<any>; // tslint:disable-line:no-any
}

const Datalaster = ({avhengigheter, feilmeldingId, children}: DatalasterProps) => {

    const feilmelding = feilmeldingId ? feilmeldingId : 'feilmelding-tekniskfeil';

    if (avhengigheter.some(a => a.status === Status.FEILET)) {
        return (
            <AlertStripe type="feil" className="limit feilmelding">
                <FormattedMessage id={feilmelding}/>
            </AlertStripe>
        );
    } else if (avhengigheter.every(a => a.status === Status.OK || a.status === Status.RELOADING)) {
        return children;
    }
    return <Spinner type="XXL"/>;
};

export default Datalaster;
