import {
    ActionType, FinnRaadSomSkalVisesAction, Handling, HentTemaFEILETAction, HentTemaOKAction,
    HentTemaPENDINGAction,
} from '../actions';
import { TemaModell, Aktivitet } from '../resultat/raad/tema-modell';
import { BrukerRaad, Raad } from '../resultat/raad/raad-modell';
import { BesvartSporsmal } from './sporsmal-duck';
import { genererRaad, getAlleSvarteAlternativer } from '../resultat/raad/raad-generering';
import { fetchThenDispatch } from '../api/fetch-utils';
import { Dispatch } from '../types';
import { getRaadFetch } from '../api/api';
import { KartleggingDto } from '../api/kartlegging-dto';
import { DataElement, Status } from '../api/datalaster';
import { uniLogger } from '../metrikker/uni-logger';

const blocksToHtml = require('@sanity/block-content-to-html');

export interface RaadState extends DataElement {
    raad: Raad[];
    brukerRaad: BrukerRaad[];
}

export const initialState: RaadState = {
    raad: [],
    brukerRaad: [],
    status: Status.IKKE_STARTET,
};

//  Reducer
export default function reducer(state: RaadState = initialState, action: Handling): RaadState {
    switch (action.type) {
        case ActionType.HENT_TEMA_OK:
            return {...state, raad: sanitize(action.temaer), status: Status.OK};
        case ActionType.HENT_TEMA_PENDING:
            return { ...state, status: Status.PENDING };
        case ActionType.HENT_TEMA_FEILET:
            uniLogger('jobbsokerkompetanse.error', { actionType: 'HENT_TEMA_FEILET'});
            return { ...state, status: Status.FEILET };
        case ActionType.FINN_RAAD_SOM_SKAL_VISES:
            const avgitteSvar = getAlleSvarteAlternativer(action.besvarteSporsmal);
            return {...state, brukerRaad: genererRaad(avgitteSvar, state.raad)};
        case ActionType.NULL_STILL_STORE:
            return {...state, brukerRaad: []};
        case ActionType.HENT_BESVARELSE_OK:
            return {...state, brukerRaad: lagBrukerRaad(action.lagretBesvarelse)};
        default:
            return state;
    }
}

function lagBrukerRaad(besvarelse: KartleggingDto): BrukerRaad[] {
    if (!besvarelse) {
        return [];
    }
    return besvarelse.raad.map(r  => ({
        id: r.raadKey,
        tittel: r.raadTittel,
        ingress: r.raadIngress,
        aktiviteter: r.raadAktiviteter.map(ra => ({
            tittel: ra.tittel,
            innhold: ra.innhold,
        })),
    }));
}

function repackAktivitet (aktivitet: Aktivitet) {
    try {
        const innhold = blocksToHtml({
            blocks: aktivitet.innhold
        });
        return { ...aktivitet, innhold };
    } catch (error) {
        return { ...aktivitet, innhold: '' };
    }
}
export function sanitize(tema: TemaModell): Raad[] {
    const steg = tema.result.map(u => {
        return {temaer: u.temaer.map(t => {
            let aktiviteter = t.aktiviteter || [];
            aktiviteter = aktiviteter.map(repackAktivitet);
            const {ingress, refid, tittel } = t;
            return { refid, tittel, aktiviteter, ingress };
        })};
    });
    return steg.map(s => s.temaer).reduce((a, b) => a.concat(b), []);
}

export function hentRaad(): (dispatch: Dispatch) => Promise<void> {
    return fetchThenDispatch<TemaModell>(() => getRaadFetch(), {
        ok: hentTemaOk,
        feilet: hentTemaFeilet,
        pending: hentTemaPending,
    });
}

function hentTemaOk(temaer: TemaModell): HentTemaOKAction {
    return {
        type: ActionType.HENT_TEMA_OK,
        temaer: temaer
    };
}

function hentTemaFeilet(): HentTemaFEILETAction {
    return {
        type: ActionType.HENT_TEMA_FEILET,
    };
}

function hentTemaPending(): HentTemaPENDINGAction {
    return {
        type: ActionType.HENT_TEMA_PENDING,
    };
}

export function finnRaadSomSkalVises(
    besvarteSporsmal: BesvartSporsmal[]): FinnRaadSomSkalVisesAction {
    return {
        type: ActionType.FINN_RAAD_SOM_SKAL_VISES,
        besvarteSporsmal: besvarteSporsmal,
    };
}
