import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Undertittel } from 'nav-frontend-typografi';

interface TipsProps {
    id: string;
}

const TipsVisning = (props: TipsProps) => {
    return (
        <>
            <Undertittel tag={'h1'} className={'tips__overskrift'}>
                <FormattedMessage id="tips-standard-overskrift"/>
            </Undertittel>
            <FormattedMessage id={props.id}/>
        </>
    );
};

export default TipsVisning;
