import * as React from 'react';
import { connect } from 'react-redux';
import { RadioPanel } from 'nav-frontend-skjema';
import { CheckboksPanel } from 'nav-frontend-skjema';
import { FormattedHTMLMessage, InjectedIntlProps, injectIntl } from 'react-intl';

import SporsmalModell from '../sporsmal/sporsmal-modell';
import { Dispatch } from '../types';
import { AppState } from '../ducks/reducer';
import { klikkAlternativ } from '../ducks/svar-duck';
import { BesvartSporsmal, sjekkAvhengigheter } from '../ducks/sporsmal-duck';
import { starteSvar, stoppForAViseNyttTips } from '../ducks/side-duck';
import { AlternativTyper } from '../utils/konstanter';
import Skalapanel from '../alternativ/skalapanel';

interface ParentProps {
    sporsmal: SporsmalModell;
}

interface StateProps {
    avgitteSvar: string[];
    besvarteSporsmal: BesvartSporsmal[];
    tips: string | undefined;
    erNySide: boolean;
    viserNyttTips: boolean;
}

interface DispatchProps {
    doKlikkAlternativ: (svarId: string,
                        erUniktAlternativ: boolean,
                        spmId: string,
                        besvarteSporsmal: BesvartSporsmal[],
                        alternativType: AlternativTyper) => void;
    doSjekkAvhengigheter: (svarId: string, spmId: string) => void;
    ikkeNySideLenger: () => void;
    doStoppForAViseNyttTips: (stopp: boolean) => void;
}

type AlternativContainerProps = ParentProps & StateProps & DispatchProps & InjectedIntlProps;

function erAlternativMulig(
    uniktAlternativId: string,
    gjeldendeAlternativ: string,
    avgitteSvar: string[]
): boolean {
    if (!!avgitteSvar.find(alt => alt === uniktAlternativId)) {
        return gjeldendeAlternativ === 'intervju-svar-0202';
    }
    return true;
}

function erUnikt(
    uniktAlternativId: string,
    gjeldendeAlternativId: string
): boolean {
    return uniktAlternativId === gjeldendeAlternativId;
}

export class AlternativContainer extends React.Component<AlternativContainerProps> {
// eslint-disable-next-line
    constructor(props: AlternativContainerProps) {
        super(props);
    }

    componentDidUpdate(prevProps: AlternativContainerProps) {
        if (
            this.props.tips !== undefined &&
            !this.props.erNySide &&
            !this.props.viserNyttTips
        ) {
            this.props.doStoppForAViseNyttTips(true);
        }
        if (this.props.tips === undefined) {
            this.props.doStoppForAViseNyttTips(false);
        }
    }

    render() {
        const {
            sporsmal,
            doKlikkAlternativ,
            doSjekkAvhengigheter,
            ikkeNySideLenger,
            avgitteSvar,
            besvarteSporsmal,
        } = this.props;

        return (
            <>
                <span className="gjemt" id="beskrivendetekst">
                    <FormattedHTMLMessage id="du-far-folgende-svar"/>
                </span>
                <ul
                    className={'alternativer inputPanelGruppe__' + sporsmal.type}
                    role="group"
                    aria-labelledby="beskrivendetekst"
                >
                    {sporsmal.alternativer.map((alternativ: string) => {
                        const erUniktAlternativ: boolean = !!sporsmal.uniktAlternativId
                            ? erUnikt(
                                sporsmal.uniktAlternativId,
                                alternativ)
                            : false;

                        const kanVelges: boolean = (!!sporsmal.uniktAlternativId && !erUniktAlternativ)
                            ? erAlternativMulig(
                                sporsmal.uniktAlternativId,
                                alternativ,
                                avgitteSvar
                            )
                            : true;

                        const erSkalaSporsmal: boolean = this.props.sporsmal.type === AlternativTyper.SKALA;
                        const erEttvalg: boolean = this.props.sporsmal.type === AlternativTyper.ETTVALG;
                        const erFlervalg: boolean = this.props.sporsmal.type === AlternativTyper.FLERVALG;
                        const alternativtekst = this.props.intl.formatMessage({id: alternativ});
                        const klikk = () => {
                            doKlikkAlternativ(
                                alternativ,
                                erUniktAlternativ,
                                sporsmal.id,
                                besvarteSporsmal,
                                sporsmal.type
                            );
                            doSjekkAvhengigheter(alternativ, sporsmal.id);
                            ikkeNySideLenger();
                        };
                        return (
                            <li key={alternativ} className={erSkalaSporsmal ? 'alternativ' : ''}>
                                {erSkalaSporsmal &&
                                <Skalapanel
                                    alternativ={alternativ}
                                    checked={avgitteSvar.includes(alternativ)}
                                    sporsmalId={sporsmal.id}
                                    disabled={!kanVelges}
                                    onChange={() => {
                                        klikk();
                                    }}
                                />
                                }
                                {erEttvalg &&
                                <RadioPanel
                                    name={sporsmal.id.toString()}
                                    checked={avgitteSvar.includes(alternativ)}
                                    label={alternativtekst}
                                    value={alternativ}
                                    disabled={!kanVelges}
                                    onChange={() => {
                                        if (kanVelges) {
                                            klikk();
                                        }
                                    }}
                                />
                                }
                                {erFlervalg &&
                                <>
                                    <CheckboksPanel
                                        checked={avgitteSvar.includes(alternativ)}
                                        label={alternativtekst}
                                        value={alternativ}
                                        disabled={!kanVelges}
                                        onChange={() => {
                                            if (kanVelges) {
                                                klikk();
                                            }
                                        }}
                                    />
                                </>
                                }
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    avgitteSvar: state.svar.avgitteSvar,
    besvarteSporsmal: state.sporsmal.besvarteSporsmal,
    tips: state.svar.tips,
    erNySide: state.side.erNySide,
    viserNyttTips: state.svar.viserNyttTips,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doKlikkAlternativ: (svarId: string,
                        erUniktAlternativ: boolean,
                        spmId: string,
                        besvarteSporsmal: BesvartSporsmal[],
                        type: AlternativTyper) => dispatch(
        klikkAlternativ(
            svarId,
            erUniktAlternativ,
            spmId,
            besvarteSporsmal,
            type
        )),
    doSjekkAvhengigheter: (svarId: string, spmId: string) => dispatch(sjekkAvhengigheter(svarId, spmId)),
    doStoppForAViseNyttTips: (stopp: boolean) => dispatch(stoppForAViseNyttTips(stopp)),
    ikkeNySideLenger: () => dispatch(starteSvar())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AlternativContainer));
