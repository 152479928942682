import { BesvartSporsmal } from '../../ducks/sporsmal-duck';

export interface Kulepunkt {
    tekstId: string;
    prioritet: number;
}

const beregnKulepunktForFinnSpm1 = (avgitteSvar: string[], kulepunkter: Kulepunkt[]): Kulepunkt[] => {
    if (avgitteSvar.length >= 3) {
        return kulepunkter.concat({
            tekstId: 'kulepunkt-prioritet7',
            prioritet: 7,
        });
    } else if (avgitteSvar.length === 2) {
        return kulepunkter.concat({
            tekstId: 'kulepunkt-prioritet14',
            prioritet: 14,
        });
    }
    return kulepunkter;
};

export const kombinasjonerErLike = (kombinasjon1: string[], kombinasjon2: string[]): boolean => {
    const sortertKombinasjon1 = kombinasjon1.sort();
    const sortertKombinasjon2 = kombinasjon2.sort();
    if (sortertKombinasjon1.length !== sortertKombinasjon2.length) {
        return false;
    }
    for (const i in sortertKombinasjon1) {
        if (sortertKombinasjon1[i] !== sortertKombinasjon2[i]) {
            return false;
        }
    }
    return true;
};

export const genererKombinasjoner = (elementer: string[]): string[][] => {
    if (elementer.length === 1) {
        return [elementer];
    }
    const kombinasjoner: string[][] = [];
    elementer.forEach((el1, i) => {
        // enkeltelementer
        kombinasjoner.push([el1]);

        // kombinasjoner av 2 elementer
        if (elementer.length >= 2) {
            elementer.slice(i + 1, elementer.length).forEach((el2, j, arr2) => {
                kombinasjoner.push([el1, el2]);

                // kombinasjoner av 3 elementer
                if (elementer.length >= 3) {
                    arr2.slice(j + 1, arr2.length).forEach((el3, k, arr3) => {
                        kombinasjoner.push([el1, el2, el3]);

                        // kombinasjoner av 4 elementer
                        if (elementer.length >= 4) {
                            arr3.slice(k + 1, arr3.length).forEach(el4 => {
                                kombinasjoner.push([el1, el2, el3, el4]);
                            });
                        }
                    });
                }
            });
        }
    });
    return kombinasjoner;
};

const beregnKulepunktForFinnSpm2 = (avgitteSvar: string[], kulepunkter: Kulepunkt[]): Kulepunkt[] => {
    const svarSomTriggerKulepunkt = ['finn-svar-0203', 'finn-svar-0204', 'finn-svar-0205', 'finn-svar-0206'];
    const kombinasjonerSomTriggerKulepunkt = genererKombinasjoner(svarSomTriggerKulepunkt);
    return kombinasjonerSomTriggerKulepunkt.some(
        kombinasjon => kombinasjonerErLike(kombinasjon, avgitteSvar)
    ) ? kulepunkter.concat({tekstId: 'kulepunkt-prioritet4', prioritet: 4}) : kulepunkter;
};

export const beregnKulepunktForFinnSpm3 = (avgitteSvar: string[], kulepunkter: Kulepunkt[]) => {
    const svarSomTriggerKulepunkt = ['finn-svar-0302', 'finn-svar-0303'];
    const kombinasjonerSomTriggerKulepunkt = genererKombinasjoner(svarSomTriggerKulepunkt);
    return kombinasjonerSomTriggerKulepunkt.some(
        kombinasjon => kombinasjonerErLike(kombinasjon, avgitteSvar)
    ) ? kulepunkter.concat({tekstId: 'kulepunkt-prioritet8', prioritet: 8}) : kulepunkter;
};

const beregnKulepunktForFinnSpm4 = (avgitteSvar: string[], kulepunkter: Kulepunkt[]): Kulepunkt[] => {
    if (avgitteSvar.length >= 3) {
        return kulepunkter.concat({
            tekstId: 'kulepunkt-prioritet2',
            prioritet: 2,
        });
    }
    return kulepunkter;
};

const beregnKulepunktForCvSpm1 = (avgitteSvar: string[], kulepunkter: Kulepunkt[]): Kulepunkt[] => {
    if (avgitteSvar.length >= 3) {
        return kulepunkter.concat({
            tekstId: 'kulepunkt-prioritet6',
            prioritet: 6,
        });
    }
    return kulepunkter;
};

const beregnKulepunktForCvSpm3 = (avgitteSvar: string[], kulepunkter: Kulepunkt[]): Kulepunkt[] => {
    if (avgitteSvar[0] === 'cv-svar-0301') {
        return kulepunkter.concat({
            tekstId: 'kulepunkt-prioritet9',
            prioritet: 9,
        });
    } else if (avgitteSvar[0] === 'cv-svar-0302') {
        return kulepunkter.concat({
            tekstId: 'kulepunkt-prioritet13',
            prioritet: 13,
        });
    }
    return kulepunkter;
};

export const fjernElementerFraArray = (avgitteSvar: string[], elementer: string[]): string[] => {
    return avgitteSvar.filter(svar => !elementer.includes(svar));
};

const beregnKulepunktForCvSpm5 = (avgitteSvar: string[], kulepunkter: Kulepunkt[]): Kulepunkt[] => {
    if (fjernElementerFraArray(avgitteSvar, ['cv-svar-0507']).length >= 3) {
        return kulepunkter.concat({
            tekstId: 'kulepunkt-prioritet5',
            prioritet: 5,
        });
    }
    return kulepunkter;
};

const beregnKulepunktForSokeSpm4 = (avgitteSvar: string[], kulepunkter: Kulepunkt[]): Kulepunkt[] => {
    if (avgitteSvar[0] === 'soke-svar-0403') {
        return kulepunkter.concat({
            tekstId: 'kulepunkt-prioritet1',
            prioritet: 1,
        });
    } else if (avgitteSvar[0] === 'soke-svar-0402') {
        return kulepunkter.concat({
            tekstId: 'kulepunkt-prioritet12',
            prioritet: 12,
        });
    }
    return kulepunkter;
};

const beregnKulepunktForIntervjuSpm2 = (avgitteSvar: string[], kulepunkter: Kulepunkt[]): Kulepunkt[] => {
    if (fjernElementerFraArray(avgitteSvar, ['intervju-svar-0201', 'intervju-svar-0202']).length >= 3) {
        return kulepunkter.concat({
            tekstId: 'kulepunkt-prioritet3',
            prioritet: 3,
        });
    } else if (fjernElementerFraArray(avgitteSvar, ['intervju-svar-0201', 'intervju-svar-0202']).length >= 2 &&
        avgitteSvar.includes('intervju-svar-0202')) {
            return kulepunkter.concat({
                tekstId: 'kulepunkt-prioritet10',
                prioritet: 10,
            });
    } else if (fjernElementerFraArray(avgitteSvar, ['intervju-svar-0201', 'intervju-svar-0202']).length >= 2) {
        return kulepunkter.concat({
            tekstId: 'kulepunkt-prioritet16',
            prioritet: 16,
        });
    }
    return kulepunkter;
};

const beregnKulepunktForIntervjuSpm4 = (avgitteSvar: string[], kulepunkter: Kulepunkt[]): Kulepunkt[] => {
    if (fjernElementerFraArray(avgitteSvar, ['intervju-svar-0408', 'intervju-svar-0409']).length >= 3) {
        return kulepunkter.concat({
            tekstId: 'kulepunkt-prioritet15',
            prioritet: 15,
        });
    } else if (fjernElementerFraArray(avgitteSvar, ['intervju-svar-0408', 'intervju-svar-0409']).length >= 2) {
        return kulepunkter.concat({
            tekstId: 'kulepunkt-prioritet11',
            prioritet: 11,
        });
    }
    return kulepunkter;
};

export const genererPositiveKulepunkter = (besvarteSporsmal: BesvartSporsmal[]): Kulepunkt[] => {
    return besvarteSporsmal.reduce(
        (kulepunkter: Kulepunkt[], besvartSpm: BesvartSporsmal) => {
            if (besvartSpm.spmId === 'finn-spm-01') {
                return beregnKulepunktForFinnSpm1(besvartSpm.svar, kulepunkter);

            } else if (besvartSpm.spmId === 'finn-spm-02') {
                return beregnKulepunktForFinnSpm2(besvartSpm.svar, kulepunkter);
            } else if (besvartSpm.spmId === 'finn-spm-03') {
                return beregnKulepunktForFinnSpm3(besvartSpm.svar, kulepunkter);
            } else if (besvartSpm.spmId === 'finn-spm-04') {
                return beregnKulepunktForFinnSpm4(besvartSpm.svar, kulepunkter);
            } else if (besvartSpm.spmId === 'cv-spm-01') {
                return beregnKulepunktForCvSpm1(besvartSpm.svar, kulepunkter);
            } else if (besvartSpm.spmId === 'cv-spm-03') {
                return beregnKulepunktForCvSpm3(besvartSpm.svar, kulepunkter);
            } else if (besvartSpm.spmId === 'cv-spm-05') {
                return beregnKulepunktForCvSpm5(besvartSpm.svar, kulepunkter);
            } else if (besvartSpm.spmId === 'soke-spm-04') {
                return beregnKulepunktForSokeSpm4(besvartSpm.svar, kulepunkter);
            } else if (besvartSpm.spmId === 'intervju-spm-02') {
                return beregnKulepunktForIntervjuSpm2(besvartSpm.svar, kulepunkter);
            } else if (besvartSpm.spmId === 'intervju-spm-04') {
                return beregnKulepunktForIntervjuSpm4(besvartSpm.svar, kulepunkter);
            }
            return kulepunkter;
        },
        []
    ).sort((a, b) => a.prioritet - b.prioritet);
};
