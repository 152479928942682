import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../types';
import { AppState } from '../ducks/reducer';
import { hentOppfolging, OppfolgingState, settOppfolgingOK } from '../ducks/oppfolging-duck';
import Datalaster, { Status } from '../api/datalaster';

interface OwnProps {
    children: React.ReactElement<any>; // tslint:disable-line:no-any
}

interface StateProps {
    oppfolging: OppfolgingState;
    harGyldigOidcToken: boolean;
}

interface DispatchProps {
    doHentOppfolging: () => void;
    doSettOppfolgingOK: () => void;
}

type OppfolgingProviderProps = OwnProps & StateProps & DispatchProps;

class OppfolgingProvider extends React.Component<OppfolgingProviderProps> {
    // eslint-disable-next-line
    constructor(props: OppfolgingProviderProps) {
        super(props);

    }

    componentDidMount() {
        const skalHenteOppfolging = this.props.harGyldigOidcToken &&
            this.props.oppfolging.status === Status.IKKE_STARTET;

        if (skalHenteOppfolging) {
            this.props.doHentOppfolging();
        } else {
            this.props.doSettOppfolgingOK();
        }
    }

    render() {
        return (
            <Datalaster avhengigheter={[this.props.oppfolging]}>
                {this.props.children}
            </Datalaster>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    oppfolging: state.oppfolging,
    harGyldigOidcToken: state.innloggingsstatus.harGyldigOidcToken,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doHentOppfolging: () => hentOppfolging()(dispatch),
    doSettOppfolgingOK: () => dispatch(settOppfolgingOK()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OppfolgingProvider);