import * as React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import getStore from './store';
import Routes from './routes';
import IntlProvider from './providers/intl-provider';
import setupMock from './mock/setup-mock';
import 'whatwg-fetch';
import { erGitHubPages, erLocalhost, erMock } from './mock/utils';
import { BrowserRouter } from 'react-router-dom';
import FeaturesProvider from './providers/features-provider';
import InnloggingsstatusProvider from './providers/innloggingsstatus-provider';
import RaadProvider from './providers/raad-provider';
import OppfolgingProvider from './providers/oppfolging-provider';
import InnloggingProvider from './providers/innlogging-provider';

if (erMock() || erLocalhost() || erGitHubPages()) {
    setupMock();
}

const store = getStore();



class App extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <StoreProvider store={store}>
                    <IntlProvider>
                        <FeaturesProvider>
                            <InnloggingsstatusProvider>
                                <InnloggingProvider>
                                    <RaadProvider>
                                        <OppfolgingProvider>
                                            <Routes/>
                                        </OppfolgingProvider>
                                    </RaadProvider>
                                </InnloggingProvider>
                            </InnloggingsstatusProvider>
                        </FeaturesProvider>
                    </IntlProvider>
                </StoreProvider>
            </BrowserRouter>
        );
    }
}

export default App;
