/* tslint:disable */

import { SideState } from '../ducks/side-duck';
import { SporsmalState } from '../ducks/sporsmal-duck';
import { RaadState } from '../ducks/raad-duck';
import { TilbakemeldingState } from '../ducks/tilbakemelding-duck';

function read(scope: string) {
    let content = localStorage.getItem(scope);
    if (!content || content === 'undefined') {
        return undefined;
    }
    content = JSON.parse(content)
    if (content && content['status']) {
        // Ugly hack for at ikke FEIL state skal leve evig
        content['status'] = 'IKKE_STARTET'
    }
    return content;
}

function write(scope: string, content: any) {
    return localStorage.setItem(scope, JSON.stringify(content));
}

type PersistentType = SideState | SporsmalState | RaadState | TilbakemeldingState;

function erBesvarelseEndret(
    scope: string,
    initialState: PersistentType
) {
    const content = localStorage.getItem(scope);
    if (!content || content === 'undefined') {
        return true;
    }
    const keysFromStorage = Object.keys(JSON.parse(content));
    const keysFromInitialState = Object.keys(initialState);

    return !(
        keysFromStorage.length === keysFromInitialState.length &&
        keysFromStorage.every(key => keysFromInitialState.some(k => k === key))
    );
}

export default (
    scope: string,
    location: Location,
    reducer: any,
    initialState: PersistentType
) => (state: any, action: any) => {
    let nState = state;
    if (
        erBesvarelseEndret(scope, initialState) ||
        erBesvarelseEndret(scope, initialState)
    ) {
        write(scope, undefined);
    }
    if (state === undefined) {
        nState = read(scope);
    }

    const rState = reducer(nState, action);

    if (rState !== nState) {
        write(scope, rState);
    }

    return rState;
};

interface LocalStorageEntry {
    sideState: string;
    raadState: string;
    sporsmalState: string;
    tilbakemeldingState: string;
}

export const localStorageEntry: LocalStorageEntry = {
    sideState: 'sideState',
    raadState: 'raadState',
    sporsmalState: 'sporsmalState',
    tilbakemeldingState: 'tilbakemeldingState',
};

export const clearLocalStorage = (): void => {
    Object.keys(localStorageEntry).forEach((key: string) => {
        localStorage.removeItem(localStorageEntry[key]);
    });
};
