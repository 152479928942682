import * as React from 'react';
import { addLocaleData, IntlProvider as Provider } from 'react-intl';
import * as nb from 'react-intl/locale-data/nb';
import alleTekster from '../tekster/alle-tekster';
import * as queryString from 'query-string';
import { Dispatch } from 'redux';
import { tekstnokkelDuck } from '../ducks/tekstnokkel-duck';
import { connect } from 'react-redux';
const { location } = window;

addLocaleData(nb);

export interface Tekster {
    [key: string]: string;
}

export function tekstNokler(tekster: Tekster) {
    return Object.keys(tekster)
    .reduce((obj, curr) => ({ ...obj, [curr]: `${tekster[curr]} [${curr}]` }), {});
}

function skalViseTekstnokler(): boolean {
    const search = queryString.parse(location.search);
    return !!search.vistekster;
}

export function utledTekster(tekster: Tekster) {
    const skalViseTekstNokler = skalViseTekstnokler();
    return skalViseTekstNokler ? tekstNokler(tekster) : tekster;
}

interface OwnProps {
    children: React.ReactElement<any>; // tslint:disable-line:no-any
}

interface DispatchProps {
    doSettVisTekstnokler: (visTekstnokler: boolean) => void;
}

type IntlProviderProps = OwnProps & DispatchProps;

class IntlProvider extends React.Component<IntlProviderProps> {
    // eslint-disable-next-line
    constructor(props: IntlProviderProps) {
        super(props);
    }

    componentDidMount() {
        const { doSettVisTekstnokler } = this.props;

        const visTekstnokler = skalViseTekstnokler();
        doSettVisTekstnokler(visTekstnokler);
    }
    render() {
        const { children } = this.props;
        const locale = 'nb';

        return (
            <Provider locale={locale} messages={utledTekster(alleTekster.nb) || []}>
                {children}
            </Provider>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doSettVisTekstnokler: (visTekstnokler) => dispatch(tekstnokkelDuck.actionCreator({skalViseTekstnokler: visTekstnokler}))
});

export default connect(null, mapDispatchToProps) (IntlProvider);