import { fetchToJson } from './fetch-utils';
import { TemaModell } from '../resultat/raad/tema-modell';
import { KartleggingDto } from './kartlegging-dto';
import { AlleFeatures } from '../ducks/feature-duck';
import { InnloggingsstatusState } from '../ducks/innloggingsstatus-duck';
import { OppfolgingState } from '../ducks/oppfolging-duck';

const getCookie = (name: string) => {
    const re = new RegExp(`${name}=([^;]+)`);
    const match = re.exec(document.cookie);
    return match !== null ? match[1] : '';
};

const requestConfig: RequestInit = {
    credentials: 'same-origin',
    headers: {
        'Content-Type': 'application/json',
        'NAV_CSRF_PROTECTION': getCookie('NAV_CSRF_PROTECTION'),
    }
};

interface ApiProps {
    getTemaer: string;
    postBesvarelse: string;
    getFeatures: string;
    getBesvarelse: string;
    getOppfolging: string;
    getStatus: string;
}

export const API: ApiProps = {
    getFeatures: '/api/feature',
    getTemaer: 'https://aq2h9zjd.api.sanity.io/v1/data/query/production?query=*%5B_type%20%3D%3D%20%27steg%27%5D%20',
    postBesvarelse: '/veilarbjobbsokerkompetanse/api/opprett',
    getBesvarelse: '/veilarbjobbsokerkompetanse/api/hent',
    getOppfolging: '/veilarbjobbsokerkompetanse/api/oppfolging',
    getStatus: '/veilarbstepup/status',
};

export function getOppfolgingFetch(): Promise<OppfolgingState> {
    return fetchToJson(API.getOppfolging, requestConfig);
}

export function getRaadFetch(): Promise<TemaModell> {
    return fetchToJson(API.getTemaer, requestConfig);
}

export function postBesvarelseFetch(besvarelse: KartleggingDto): Promise<KartleggingDto> {
    const config = {...requestConfig, method: 'post', body: JSON.stringify(besvarelse)};
    return fetchToJson(API.postBesvarelse, config);
}

export function getBesvarelseFetch(): Promise<KartleggingDto> {
    return fetchToJson(API.getBesvarelse, requestConfig);
}

export function getFeaturesFetch(): Promise<AlleFeatures | {}> {
    return fetchToJson(API.getFeatures, requestConfig)
        .catch(() => new Promise((resolve) => {
            resolve({});
        }));
}

export function getInnloggingsstatusFetch(): Promise<InnloggingsstatusState> {
    return fetchToJson(API.getStatus, requestConfig);
}
