import { AlternativTyper, Sidetype } from './utils/konstanter';
import { TemaModell } from './resultat/raad/tema-modell';
import { BesvartSporsmal } from './ducks/sporsmal-duck';
import { KartleggingDto } from './api/kartlegging-dto';
import { AlleFeatures } from './ducks/feature-duck';
import { InnloggingsstatusState } from './ducks/innloggingsstatus-duck';
import { OppfolgingState } from './ducks/oppfolging-duck';
import { Action } from 'redux';

export enum ActionType {
    ENDRE_ALTERNATIV = 'ENDRE_ALTERNATIV',
    ENDRE_ALTERNATIV_OG_ANTALL = 'ENDRE_ALTERNATIV_OG_ANTALL',
    BYTT_SPORSMAL = 'BYTT_SPORSMAL',
    STARTE_SVAR = 'STARTE_SVAR',
    ENDRE_SIDE = 'ENDRE_SIDE',
    HENT_TEMA_OK = 'HENT_TEMA_OK',
    HENT_TEMA_FEILET = 'HENT_TEMA_FEILET',
    HENT_TEMA_PENDING = 'HENT_TEMA_PENDING',
    LAGRE_BESVARELSE_OK = 'LAGRE_BESVARELSE_OK',
    LAGRE_BESVARELSE_FEILET = 'LAGRE_BESVARELSE_FEILET',
    LAGRE_BESVARELSE_PENDING = 'LAGRE_BESVARELSE_PENDING',
    HENT_BESVARELSE_OK = 'HENT_BESVARELSE_OK',
    HENT_BESVARELSE_FEILET = 'HENT_BESVARELSE_FEILET',
    HENT_BESVARELSE_PENDING = 'HENT_BESVARELSE_PENDING',
    HENT_FEATURES_OK = 'HENT_FEATURES_OK',
    HENT_FEATURES_FEILET = 'HENT_FEATURES_FEILET',
    HENT_FEATURES_PENDING = 'HENT_FEATURES_PENDING',
    HENT_OPPFOLGING_OK = 'HENT_OPPFOLGING_OK',
    HENT_OPPFOLGING_FEILET = 'HENT_OPPFOLGING_FEILET',
    HENT_OPPFOLGING_PENDING = 'HENT_OPPFOLGING_PENDING',
    SETT_OPPFOLGING_OK = 'SETT_OPPFOLGING_OK',
    HENT_INNLOGGINGSSTATUS_OK = 'HENT_INNLOGGINGSSTATUS_OK',
    HENT_INNLOGGINGSSTATUS_FEILET = 'HENT_INNLOGGINGSSTATUS_FEILET',
    HENT_INNLOGGINGSSTATUS_PENDING = 'HENT_INNLOGGINGSSTATUS_PENDING',
    LEGGE_TIL_SPORSMAL = 'LEGGE_TIL_SPORSMAL',
    LEGG_TIL_BESVART_SPORSMAL = 'LEGG_TIL_BESVART_SPORSMAL',
    KLIKK_ALTERNATIV = 'KLIKK_ALTERNATIV',
    SJEKK_AVHENGIGHETER = 'SJEKK_AVHENGIGHETER',
    NULL_STILL_AVGITTE_SVAR = 'NULL_STILL_AVGITTE_SVAR',
    SKAL_STOPPE_FOR_A_VISE_NYTT_TIPS = 'SKAL_STOPPE_FOR_A_VISE_NYTT_TIPS',
    VIS_NYTT_TIPS = 'VIS_NYTT_TIPS',
    LAST_INN_BESVART_SPORSMAL = 'LAST_INN_BESVART_SPORSMAL',
    NULL_STILL_STORE = 'NULL_STILL_STORE',
    FINN_RAAD_SOM_SKAL_VISES = 'FINN_RAAD_SOM_SKAL_VISES',
    GENERER_KULEPUNKTER = 'GENERER_KULEPUNKTER',
    GENERER_OPPSUMMERING = 'GENERER_OPPSUMMERING',
    SETT_API_OK = 'SETT_API_OK',
    SETT_TEKSTNOKKEL = 'SETT_TEKSTNOKKEL',
}

export interface LeggeTilSporsmalAction extends Action {
    type: ActionType.LEGGE_TIL_SPORSMAL;
    spmId: string;
}

export interface EndreAlternativAction extends Action {
    type: ActionType.ENDRE_ALTERNATIV;
    data: {
        sporsmalId: string;
        svarAlternativer: string[];
    };
}

export interface EndreAlternativOgAntallAction extends Action {
    type: ActionType.ENDRE_ALTERNATIV_OG_ANTALL;
    data: {
        sporsmalId: string;
        svarAlternativer: string[];
        totalAntallSpm: number;
    };
}

export interface ByttSporsmalAction extends Action {
    type: ActionType.BYTT_SPORSMAL;
    spmId: string;
    spmErBesvart: boolean;
}

export interface StarteSvarAction extends Action {
    type: ActionType.STARTE_SVAR;
}

export interface EndreSideAction extends Action {
    type: ActionType.ENDRE_SIDE;
    side: Sidetype;
    spmId: string;
}

export interface HentTemaOKAction extends Action {
    type: ActionType.HENT_TEMA_OK;
    temaer: TemaModell;
}

export interface HentTemaPENDINGAction extends Action {
    type: ActionType.HENT_TEMA_PENDING;
}

export interface HentTemaFEILETAction extends Action {
    type: ActionType.HENT_TEMA_FEILET;
}

export interface LagreBesvarelseOKAction extends Action {
    type: ActionType.LAGRE_BESVARELSE_OK;
    lagretBesvarelse: KartleggingDto;
}

export interface LagreBesvarelsePENDINGAction extends Action {
    type: ActionType.LAGRE_BESVARELSE_PENDING;
}

export interface LagreBesvarelseFEILETAction extends Action {
    type: ActionType.LAGRE_BESVARELSE_FEILET;
}

export interface HentBesvarelseOKAction extends Action {
    type: ActionType.HENT_BESVARELSE_OK;
    lagretBesvarelse: KartleggingDto;
}

export interface HentBesvarelsePENDINGAction extends Action {
    type: ActionType.HENT_BESVARELSE_PENDING;
}

export interface HentBesvarelseFEILETAction extends Action {
    type: ActionType.HENT_BESVARELSE_FEILET;
}

export interface SettApiOKAction extends Action {
    type: ActionType.SETT_API_OK;
}

export interface HentFeaturesOKAction extends Action {
    type: ActionType.HENT_FEATURES_OK;
    features: AlleFeatures;
}

export interface HentFeaturesPENDINGAction extends Action {
    type: ActionType.HENT_FEATURES_PENDING;
}

export interface HentFeaturesFEILETAction extends Action {
    type: ActionType.HENT_FEATURES_FEILET;
}

export interface HentOppfolgingOKAction extends Action {
    type: ActionType.HENT_OPPFOLGING_OK;
    oppfolging: OppfolgingState;
}

export interface HentOppfolgingPENDINGAction extends Action {
    type: ActionType.HENT_OPPFOLGING_PENDING;
}

export interface HentOppfolgingFEILETAction extends Action {
    type: ActionType.HENT_OPPFOLGING_FEILET;
}

export interface SettOppfolgingOKAction extends Action {
    type: ActionType.SETT_OPPFOLGING_OK;
}

export interface HentInnloggingsstatusOKAction extends Action {
    type: ActionType.HENT_INNLOGGINGSSTATUS_OK;
    status: InnloggingsstatusState;
}

export interface HentInnloggingsstatusPENDINGAction extends Action {
    type: ActionType.HENT_INNLOGGINGSSTATUS_PENDING;
}

export interface HentInnloggingsstatusFEILETAction extends Action {
    type: ActionType.HENT_INNLOGGINGSSTATUS_FEILET;
}

export interface LeggTilBesvartSporsmalAction extends Action {
    type: ActionType.LEGG_TIL_BESVART_SPORSMAL;
    spmId: string;
    svar: string[];
    tips: string | undefined;
}

export interface KlikkAlternativAction extends Action {
    type: ActionType.KLIKK_ALTERNATIV;
    svarId: string;
    erUniktAlternativ: boolean;
    spmId: string;
    alternativType: AlternativTyper;
    besvarteSporsmal: BesvartSporsmal[];
}

export interface NullStillAvgitteSvarAction extends Action {
    type: ActionType.NULL_STILL_AVGITTE_SVAR;
}

export interface StoppForAViseNyttTipsAction extends Action {
    type: ActionType.SKAL_STOPPE_FOR_A_VISE_NYTT_TIPS;
    stopp: boolean;
}

export interface VisNyttTipsAction extends Action {
    type: ActionType.VIS_NYTT_TIPS;
    viserNyttTips: boolean;
}

export interface LastInnBesvartSporsmalAction extends Action {
    type: ActionType.LAST_INN_BESVART_SPORSMAL;
    svar: string[];
    tips: string | undefined;
}

export interface NullStillStoreAction extends Action {
    type: ActionType.NULL_STILL_STORE;
}

export interface FinnRaadSomSkalVisesAction extends Action {
    type: ActionType.FINN_RAAD_SOM_SKAL_VISES;
    besvarteSporsmal: BesvartSporsmal[];
}

export interface GenererKulepunkterAction extends Action {
    type: ActionType.GENERER_KULEPUNKTER;
    besvarteSporsmal: BesvartSporsmal[];
}

export interface GenererOppsummeringAction extends Action {
    type: ActionType.GENERER_OPPSUMMERING;
    besvarteSporsmal: BesvartSporsmal[];
}

export interface SjekkAvhengigheterAction extends Action {
    type: ActionType.SJEKK_AVHENGIGHETER;
    svarId: string;
    spmId: string;
}

export type Handling =
    | EndreAlternativAction
    | EndreAlternativOgAntallAction
    | ByttSporsmalAction
    | StarteSvarAction
    | EndreSideAction
    | HentTemaOKAction
    | HentTemaPENDINGAction
    | HentTemaFEILETAction
    | LeggeTilSporsmalAction
    | LeggTilBesvartSporsmalAction
    | KlikkAlternativAction
    | SjekkAvhengigheterAction
    | NullStillAvgitteSvarAction
    | StoppForAViseNyttTipsAction
    | VisNyttTipsAction
    | LastInnBesvartSporsmalAction
    | NullStillStoreAction
    | FinnRaadSomSkalVisesAction
    | GenererKulepunkterAction
    | GenererOppsummeringAction
    | LagreBesvarelseOKAction
    | LagreBesvarelseFEILETAction
    | LagreBesvarelsePENDINGAction
    | HentBesvarelseOKAction
    | HentBesvarelseFEILETAction
    | HentBesvarelsePENDINGAction
    | HentFeaturesOKAction
    | HentFeaturesFEILETAction
    | HentFeaturesPENDINGAction
    | HentInnloggingsstatusOKAction
    | HentInnloggingsstatusFEILETAction
    | HentInnloggingsstatusPENDINGAction
    | HentOppfolgingOKAction
    | HentOppfolgingPENDINGAction
    | HentOppfolgingFEILETAction
    | SettOppfolgingOKAction
    | SettApiOKAction;
