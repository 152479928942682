import * as React from 'react';
import { Kulepunkt as IKulepunkt } from './kulepunkter-generering';
import { connect } from 'react-redux';
import { AppState } from '../../ducks/reducer';
import { Normaltekst } from 'nav-frontend-typografi';
import { FormattedMessage } from 'react-intl';
import { Systemtittel } from 'nav-frontend-typografi';
import { kulepunkterTilBruker } from './kulepunkter-selectors';

interface KulepunktProps {
    kulepunkt: IKulepunkt;
}

function Kulepunkt({kulepunkt}: KulepunktProps) {
    return (
        <li className="kulepunkt">
            <Normaltekst>
                <FormattedMessage id={kulepunkt.tekstId}/>
            </Normaltekst>
        </li>
    );
}

interface StateProps {
    kulepunkter: IKulepunkt[];
}

interface OwnProps {
    erAdmin: boolean;
}

type KulepunkterProps = StateProps & OwnProps;

function Kulepunkter({kulepunkter, erAdmin}: KulepunkterProps) {
    const kulepunkterIkon = require('../../ikoner/kulepunkter.svg');
    const kulepunkterSomVises = erAdmin ? kulepunkter : kulepunkterTilBruker(kulepunkter);
    return (
        <div className="resultat__kulepunkter">
            <img src={kulepunkterIkon} className="kulepunkter__bilde" alt=""/>
            <div className="kulepunkter__innhold">
                <Systemtittel className="kulepunkter__overskrift">
                    <FormattedMessage id="kulepunkter-overskrift"/>
                </Systemtittel>
                <ul className="kulepunktliste">
                    {kulepunkterSomVises.map(kulepunkt =>
                        <Kulepunkt
                            key={kulepunkt.tekstId}
                            kulepunkt={kulepunkt}
                        />
                    )}
                </ul>
            </div>
        </div>
    );
}

const mapStateToProps = (state: AppState): StateProps => ({
    kulepunkter: state.tilbakemelding.kulepunkter,
});

export default connect(mapStateToProps)(Kulepunkter);
