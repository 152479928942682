import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../ducks/reducer';
import { Normaltekst } from 'nav-frontend-typografi';
import { FormattedMessage } from 'react-intl';
import { Systemtittel } from 'nav-frontend-typografi';
import { Oppsummering } from './oppsummering-generering';

interface OppsummeringProps {
    oppsummering: Oppsummering;
}

function OppsummeringVisning({oppsummering}: OppsummeringProps) {
    const oppsummeringIkon = require('../../ikoner/oppsummering.svg');
    return (
        <div className="resultat__oppsummering">
            <img src={oppsummeringIkon} className="oppsummering__bilde" alt=""/>
            <div className="oppsummering__tekst">
                <Systemtittel className="oppsummering__overskrift">
                    <FormattedMessage id="oppsummering-overskrift"/>
                </Systemtittel>
                <Normaltekst>
                    <FormattedMessage id={oppsummering.tekstId}/>
                </Normaltekst>
            </div>
        </div>
    );
}

const mapStateToProps = (state: AppState): OppsummeringProps => ({
    oppsummering: state.tilbakemelding.oppsummering,
});

export default connect(mapStateToProps)(OppsummeringVisning);
