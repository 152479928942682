import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Resultat from './resultat';
import { locationStateConstants, URL_ADMIN } from '../routes';
import RedirectStartside from '../redirect-startside';
import { connect } from 'react-redux';
import { AppState } from '../ducks/reducer';
import { ApiState, hentNyesteBesvarelse, settApiOK } from '../ducks/api-duck';
import { Dispatch } from '../types';
import Datalaster from '../api/datalaster';
import { BrukerRaad } from './raad/raad-modell';
import { uniLogger } from '../metrikker/uni-logger';

interface StateProps {
    harLagretInnevaerendeKartleggingIDatabasen: boolean;
    underOppfolging: boolean;
    api: ApiState;
    brukerRaad: BrukerRaad[];
}

interface DispatchProps {
    doHentNyesteBesvarelse: () => void;
    doSettApiOK: () => void;
}

type ResultatSideProps = RouteComponentProps<{}> & StateProps & DispatchProps;

export class ResultatSide extends React.Component<ResultatSideProps> {
    // eslint-disable-next-line
    constructor(props: ResultatSideProps) {
        super(props);
    }

    kommerFraSisteSpm() {
        const {location} = this.props;
        return location && location.state && location.state[locationStateConstants.kommerFraSisteSpm];
    }

    kommerFraLagre() {
        const {location} = this.props;
        return location && location.state && location.state[locationStateConstants.kommerFraLagre];
    }

    kommerFraRaadside() {
        const {location} = this.props;
        return location && location.state && location.state[locationStateConstants.kommerFraRaadside];
    }

    skalHenteBesvarelse() {
        const {underOppfolging} = this.props;

        return underOppfolging
            && !this.kommerFraSisteSpm()
            && !this.kommerFraLagre()
            && !this.kommerFraRaadside()
            && !this.erAdmin();
    }

    componentDidMount() {
        if (this.skalHenteBesvarelse()) {
            uniLogger('jobbsokerkompetanse.resultat.gjentatt.visning', {});
            this.props.doHentNyesteBesvarelse();
        } else {
            uniLogger('jobbsokerkompetanse.resultat.ny.visning', {});
            this.props.doSettApiOK();
        }
    }

    erAdmin() {
        return this.props.match.path === URL_ADMIN;
    }

    render() {
        const {harLagretInnevaerendeKartleggingIDatabasen, api, brukerRaad} = this.props;

        const gaTilResultat = harLagretInnevaerendeKartleggingIDatabasen
            || this.kommerFraSisteSpm() || this.kommerFraLagre() || this.kommerFraRaadside();

        const tillatAdmin = this.erAdmin() && brukerRaad.length > 0;

        return (
            <Datalaster avhengigheter={[api]} feilmeldingId="feilmelding-lagre">
                {(gaTilResultat || tillatAdmin)
                    ? <Resultat erAdmin={this.erAdmin()} kommerFraRaadside={this.kommerFraRaadside()}/>
                    : <RedirectStartside/>
                }
            </Datalaster>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    harLagretInnevaerendeKartleggingIDatabasen: state.api.harLagretInnevaerendeKartleggingIDatabasen,
    underOppfolging: state.oppfolging.underOppfolging,
    api: state.api,
    brukerRaad: state.raad.brukerRaad,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doHentNyesteBesvarelse: () => hentNyesteBesvarelse()(dispatch),
    doSettApiOK: () => dispatch(settApiOK()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResultatSide));
