import * as React from 'react';
import { FormattedMessage } from 'react-intl';

interface SkalaProps {
    alternativ: string;
    checked: boolean;
    onChange: () => void;
    sporsmalId: string;
    disabled: boolean;
}

function Skalapanel({
        alternativ,
        checked,
        onChange,
        sporsmalId,
        disabled
    }: SkalaProps) {

    return (
        <>
            <input
                id={alternativ}
                className="skjemaelement__input skala"
                type="radio"
                name={sporsmalId.toString()}
                defaultValue={alternativ}
                checked={checked}
                disabled={disabled}
                onFocus={() => {
                    if (!disabled) {
                        onChange();
                    }
                }}
                onChange={() => {
                    if (!disabled) {
                        onChange();
                    }
                }}
            />
            <label
                htmlFor={alternativ}
                className={`skjemaelement__label typo-systemtittel ${checked ? 'markert' : ''}`}
            >
                <FormattedMessage
                    id={alternativ}
                />
            </label>
        </>
    );
}
export default Skalapanel;