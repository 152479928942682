import * as React from 'react';
import { AppState } from '../../ducks/reducer';
import { Dispatch } from '../../types';
import { connect } from 'react-redux';
import { lagreBesvarelse } from '../../ducks/api-duck';
import { KartleggingDto } from '../../api/kartlegging-dto';
import { BrukerRaad } from '../raad/raad-modell';
import { BesvartSporsmal } from '../../ducks/sporsmal-duck';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Redirect } from 'react-router';
import { Kulepunkt } from '../kulepunkt/kulepunkter-generering';
import { Oppsummering } from '../oppsummering/oppsummering-generering';
import { kulepunkterTilBruker } from '../kulepunkt/kulepunkter-selectors';
import { raadTilBruker } from '../raad/raad-selectors';
import { viserKulepunkter, viserOppsummering } from '../resultat';
import { Sidetype } from '../../utils/konstanter';
import { locationStateConstants } from '../../routes';

interface StateProps {
    besvarteSporsmal: BesvartSporsmal[];
    brukerRaad: BrukerRaad[];
    kulepunkter: Kulepunkt[];
    oppsummering: Oppsummering;
    underOppfolging: boolean;
    skalViseTekstnokler: boolean;
}

interface DispatchProps {
    doLagreBesvarelse: (besvarelse: KartleggingDto) => void;
}

export type LagreResultaterProps = StateProps & DispatchProps & InjectedIntlProps;

export class LagreResultater extends React.Component<LagreResultaterProps> {
    // eslint-disable-next-line
    constructor(props: LagreResultaterProps) {
        super(props);
    }

    componentDidMount() {
        if (this.props.underOppfolging) {
            this.props.doLagreBesvarelse(this.lagBesvarelse());
        } else {
            (window as any).frontendlogger.event('jobbsokerkompetanse.forsoker-lagre_ikke-under-oppfolging', {}, {}); // tslint:disable-line
        }
    }

    finnTekst(tekstId: string): string {
        return this.props.intl.formatMessage({id: tekstId});
    }

    lagBesvarelse(): KartleggingDto {
        const { kulepunkter, oppsummering, brukerRaad, besvarteSporsmal } = this.props;
        return {
            besvarelse: besvarteSporsmal.map(besvartSpm => ({
                sporsmalKey: besvartSpm.spmId,
                sporsmal: this.finnTekst(besvartSpm.spmId),
                tipsKey: besvartSpm.tips,
                tips: besvartSpm.tips ? this.finnTekst(besvartSpm.tips) : undefined,
                svarAlternativer: besvartSpm.svar.map(svarAlternativ => ({
                    svarAlternativKey: svarAlternativ,
                    svarAlternativ: this.finnTekst(svarAlternativ),
                })),
            })),
            raad: raadTilBruker(brukerRaad).map(raad => ({
                raadKey: raad.id,
                raadTittel: raad.tittel,
                raadIngress: raad.ingress,
                raadAktiviteter: raad.aktiviteter.map(aktivitet => ({
                    tittel: aktivitet.tittel,
                    innhold: aktivitet.innhold
                })),
            })),
            kulepunkter: viserKulepunkter(kulepunkter) ? kulepunkterTilBruker(kulepunkter).map(kp => ({
                kulepunktKey: kp.tekstId,
                kulepunktPrioritet: kp.prioritet,
                kulepunkt: this.finnTekst(kp.tekstId),
            })) : [],
            oppsummeringKey: viserOppsummering(kulepunkter, oppsummering.tekstId) ? oppsummering.tekstId : '',
            oppsummering: viserOppsummering(kulepunkter, oppsummering.tekstId) ?
                this.finnTekst(oppsummering.tekstId) : '',
        };
    }

    render() {
        const { skalViseTekstnokler } = this.props;
        const query = skalViseTekstnokler ? '?vistekster=true' : '';
        return (
            <Redirect
                to={{pathname: `/${Sidetype.RESULTAT}`, search: query, state: {[locationStateConstants.kommerFraLagre]: true}}}
            />
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    besvarteSporsmal: state.sporsmal.besvarteSporsmal,
    brukerRaad: state.raad.brukerRaad,
    kulepunkter: state.tilbakemelding.kulepunkter,
    oppsummering: state.tilbakemelding.oppsummering,
    underOppfolging: state.oppfolging.underOppfolging,
    skalViseTekstnokler: state.tekstnokkel.skalViseTekstnokler
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doLagreBesvarelse: (besvarelse: KartleggingDto) => lagreBesvarelse(besvarelse)(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LagreResultater));
