import * as React from 'react';
import SporsmalModell from './sporsmal-modell';

interface OwnProps {
    sporsmal: SporsmalModell;
    sporsmalSomVises: string[];
    lagretSpmId: string;
}

export class Framdrift extends React.Component<OwnProps> {
    private framdriftContainer?: HTMLDivElement;

    scrolling() {
        let scrollHeight = 0;
        const header = document.querySelector('.siteheader');
        if (header) {
            scrollHeight = header.getBoundingClientRect().height;
        }
        if (window.pageYOffset >= scrollHeight) {
            if (this.framdriftContainer) {
                this.framdriftContainer.classList.add('framdrift-fixed');
            }
        } else {
            if (this.framdriftContainer) {
                this.framdriftContainer.classList.remove('framdrift-fixed');
            }
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
            this.scrolling();
        });
    }

    shouldComponentUpdate(nextProps: OwnProps) {
        return nextProps.lagretSpmId !== this.props.lagretSpmId;
    }

    componentDidUpdate() {
        this.scrolling();
    }

    render() {
        const {sporsmal, sporsmalSomVises} = this.props;
        const gjeldendeSpmIndex = sporsmalSomVises.indexOf(sporsmal.id);
        const framdriftBredde = Math.round((gjeldendeSpmIndex + 1) / sporsmalSomVises.length * 100);

        /** @type {{search: React.CSSProperties}} */
        const framdriftStyle = {
            width: framdriftBredde + '%'
        };

        return (
            <div
                ref={(div: HTMLDivElement) => this.framdriftContainer = div}
                className="framdrift"
                role="progressbar"
                aria-valuenow={Math.round(framdriftBredde)}
                aria-valuemin={0}
                aria-valuemax={100}
                aria-label={framdriftBredde + '% gjennomført'}
                tabIndex={0}
            >
                <div
                    className="andel"
                    style={framdriftStyle}
                />
                {this.scrolling()}
            </div>
        );
    }
}
