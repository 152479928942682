export enum AlternativTyper {
    ETTVALG = 'ettvalg',
    FLERVALG = 'flervalg',
    SKALA = 'skala'
}

export enum Sidetype {
    START = 'startside',
    KARTLEGGING = 'kartleggingside',
    RESULTAT = 'resultatside'
}

export const AMPLITUDE_API_KEY_TEST = '2f190e67f31d7e4719c5ff048ad3d3e6';
export const AMPLITUDE_API_KEY_PROD = 'b0bccdd4dd75081606ef7bcab668a7ed';
export const AMPLITUDE_ENDPOINT = 'amplitude.nav.no/collect';
