/* eslint-disable */
import * as React from 'react';
import { Normaltekst } from 'nav-frontend-typografi';
import { FormattedMessage } from 'react-intl';
import NavFrontendModal from 'nav-frontend-modal';

const veilederBilde = require('../../ikoner/veileder.svg');

interface OwnProps {
    ingress: string;
    modalIsOpen: boolean;
    onClose: () => void;
}

function LoggInnModal({ingress, modalIsOpen, onClose}: OwnProps) {

    return (
        <NavFrontendModal
            isOpen={modalIsOpen}
            onRequestClose={() => onClose() }
            closeButton={false}
            contentLabel="Logg inn for å lagre"
            ariaHideApp={false}
        >
            <div className="wrapper">
                <div className="modal__ikon">
                    <img src={veilederBilde} alt={''}/>
                </div>
                <div className="modal__tekst">
                    <Normaltekst>
                        <FormattedMessage id={ingress}/>
                    </Normaltekst>
                </div>
                <section className="modal__knapper">
                    <a
                        className="knapp login knapp--hoved knapp--full"
                        href={'/veilarbstepup/oidc?url=/resultatside/lagre'} // tslint:disable-line
                        onClick={() => onClose() }
                    >
                        <FormattedMessage id="resultat-logg-inn-knapp"/>
                    </a>
                    <a
                        className="knapp avbryt knapp--full"
                        onClick={() => onClose()}
                    >
                        <FormattedMessage id="resultat-avbryt-knapp"/>
                    </a>
                </section>
            </div>
        </NavFrontendModal>
    );
}
export default LoggInnModal;