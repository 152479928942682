interface Aktivitet {
    tittel: string;
    innhold: string;
}

export interface Raad {
    tittel: string;
    refid: string;
    ingress: string;
    aktiviteter: Aktivitet[];
}

export enum DefaultRaadType {
    FAST,
    LEGGES_TIL,
    INGEN
}

export interface UtledetRaadModell {
    ref: string;
    refid: string;
    tekst: string;
    prioritet: number;
    defaultPosisjon: DefaultRaadType;
}

export interface BrukerRaad {
    id: string;
    tittel: string;
    ingress: string;
    aktiviteter: Aktivitet[];
}
