import {
    ActionType,
    Handling,
    HentBesvarelseFEILETAction,
    HentBesvarelseOKAction,
    HentBesvarelsePENDINGAction,
    LagreBesvarelseFEILETAction,
    LagreBesvarelseOKAction,
    LagreBesvarelsePENDINGAction, SettApiOKAction,
} from '../actions';
import { KartleggingDto } from '../api/kartlegging-dto';
import { Dispatch } from '../types';
import { getBesvarelseFetch, postBesvarelseFetch } from '../api/api';
import { fetchThenDispatch } from '../api/fetch-utils';
import { DataElement, Status } from '../api/datalaster';

export interface ApiState extends DataElement {
    harLagretInnevaerendeKartleggingIDatabasen: boolean;
}

export const initialState: ApiState = {
    harLagretInnevaerendeKartleggingIDatabasen: false,
    status: Status.IKKE_STARTET,
};

//  Reducer
export default function reducer(state: ApiState = initialState, action: Handling): ApiState {
    switch (action.type) {
        case ActionType.LAGRE_BESVARELSE_OK:
            return { ...state, harLagretInnevaerendeKartleggingIDatabasen: true, status: Status.OK };
        case ActionType.LAGRE_BESVARELSE_PENDING:
            return { ...state, status: Status.PENDING };
        case ActionType.LAGRE_BESVARELSE_FEILET:
            return { ...state, status: Status.FEILET };
        case ActionType.HENT_BESVARELSE_OK:
            return {
                ...state,
                harLagretInnevaerendeKartleggingIDatabasen: sjekkOmHarBesvarelse(action.lagretBesvarelse),
                status: Status.OK
            };
        case ActionType.HENT_BESVARELSE_PENDING:
            return { ...state, status: Status.PENDING };
        case ActionType.HENT_BESVARELSE_FEILET:
            return { ...state, status: Status.FEILET };
        case ActionType.SETT_API_OK:
            return { ...state, status: Status.OK };
        case ActionType.NULL_STILL_STORE:
            return { ...state, harLagretInnevaerendeKartleggingIDatabasen: false };
        default:
            return state;
    }
}

function sjekkOmHarBesvarelse(besvarelse: KartleggingDto) {
    return besvarelse !== null;
}

export function lagreBesvarelse(besvarelse: KartleggingDto): (dispatch: Dispatch) => Promise<void> {
    return fetchThenDispatch<KartleggingDto>(() => postBesvarelseFetch(besvarelse), {
        ok: lagreBesvarelseOK,
        feilet: lagreBesvarelseFEILET,
        pending: lagreBesvarelsePENDING,
    });
}

function lagreBesvarelseOK(lagretBesvarelse: KartleggingDto): LagreBesvarelseOKAction {
    return {
        type: ActionType.LAGRE_BESVARELSE_OK,
        lagretBesvarelse,
    };
}

function lagreBesvarelseFEILET(): LagreBesvarelseFEILETAction {
    return {
        type: ActionType.LAGRE_BESVARELSE_FEILET,
    };
}

function lagreBesvarelsePENDING(): LagreBesvarelsePENDINGAction {
    return {
        type: ActionType.LAGRE_BESVARELSE_PENDING,
    };
}

export function hentNyesteBesvarelse(): (dispatch: Dispatch) => Promise<void> {
    return fetchThenDispatch<KartleggingDto>(() => getBesvarelseFetch(), {
        ok: hentBesvarelseOK,
        feilet: hentBesvarelseFEILET,
        pending: hentBesvarelsePENDING,
    });
}

function hentBesvarelseOK(lagretBesvarelse: KartleggingDto): HentBesvarelseOKAction {
    return {
        type: ActionType.HENT_BESVARELSE_OK,
        lagretBesvarelse,
    };
}

function hentBesvarelseFEILET(): HentBesvarelseFEILETAction {
    return {
        type: ActionType.HENT_BESVARELSE_FEILET,
    };
}

function hentBesvarelsePENDING(): HentBesvarelsePENDINGAction {
    return {
        type: ActionType.HENT_BESVARELSE_PENDING,
    };
}

export const settApiOK = (): SettApiOKAction => ({
    type: ActionType.SETT_API_OK,
});
