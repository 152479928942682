import * as React from 'react';
import Helmet from 'react-helmet';
import { BrukerRaad } from '../resultat/raad/raad-modell';
import { Sidetittel, Normaltekst } from 'nav-frontend-typografi';
import { FormattedMessage } from 'react-intl';
import { AppState } from '../ducks/reducer';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Sidetype } from '../utils/konstanter';
import { raadBilde } from '../resultat/raad/raad-visning';
import { locationStateConstants } from '../routes';
import LinkMedQueryParams from '../link-med-query-params';

interface StateProps {
    brukerRaad: BrukerRaad[];
}

interface UrlProps {
    raadId: string;
    index: string;
}

export type RaadSideProps = StateProps & RouteComponentProps<UrlProps>;

class Raadside extends React.PureComponent<RaadSideProps, {}> {
    // eslint-disable-next-line
    constructor(props: RaadSideProps) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const {brukerRaad}: RaadSideProps = this.props;
        const tilbakeUrl = '/' + Sidetype.RESULTAT;
        const locationState = { [locationStateConstants.kommerFraRaadside]: true };

        const {raadId, index} = this.props.match.params;
        const raadSomSkalVises = brukerRaad.find(raad => raad.id === raadId)!;

        let htmlInnhold = '';
        if (raadSomSkalVises.aktiviteter.length !== 0) {
            raadSomSkalVises.aktiviteter.map(a => (
                htmlInnhold += (`
                    <div class="raad__avsnitt">
                        <h2 class="typo-undertittel">${a.tittel}</h2>
                        <p>${a.innhold}</p>
                    </div>
                `)
            ));
        }

        return (
            <>
                <Helmet>
                    <body className="hodefot_skjult"/>
                </Helmet>
                <section className="raadside">

                    <div className="raadside__header">
                        <div className="header limit">
                            <LinkMedQueryParams
                                url={tilbakeUrl}
                                state={locationState}
                                className="lukknapp"
                            >
                                <FormattedMessage id="tilbake-til-resultatsiden-knapp"/>
                            </LinkMedQueryParams>

                            <div className="tilbakelenke">
                                <i className="nav-frontend-chevron chevronboks chevron--venstre"/>
                                <LinkMedQueryParams
                                    url={tilbakeUrl}
                                    state={locationState}
                                    className="lenke"
                                >
                                    <FormattedMessage id="se-alle-raad"/>
                                </LinkMedQueryParams>
                            </div>

                            <div className="headertekst">
                                <Sidetittel tag="h1" className="blokk-xxs">
                                    {raadSomSkalVises.tittel}
                                </Sidetittel>
                                <Normaltekst>
                                    {raadSomSkalVises.ingress}
                                </Normaltekst>
                            </div>
                            <div className="headerbilde">
                                <img
                                    src={raadBilde(raadSomSkalVises.id, Number(index))}
                                    alt=""
                                    className="raadside__headerbilde"
                                    role="img"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="raadside__innhold">
                        <div className="limit" dangerouslySetInnerHTML={{__html: htmlInnhold}}/>
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    brukerRaad: state.raad.brukerRaad,
});

export default connect(mapStateToProps)(Raadside);
