import FetchMock, { Middleware, MiddlewareUtils } from 'yet-another-fetch-mock';
import { API } from '../api/api';
import { besvarelse } from './besvarelse-mock';
const steg = require('./sanity.json');

export default () => {

    const loggingMiddleware: Middleware = (request, response) => {
        console.log(request.url, response); // tslint:disable-line:no-console
        return response;
    };

    const fetchMock = FetchMock.configure({
        enableFallback: true, // default: true
        middleware: MiddlewareUtils.combine(
            MiddlewareUtils.delayMiddleware(200),
            MiddlewareUtils.failurerateMiddleware(0.01),
            loggingMiddleware
        )
    });

    console.log('### FULL MOCK AKTIVERT! ###'); // tslint:disable-line:no-console

    fetchMock.get(API.getTemaer, steg);

    fetchMock.post(API.postBesvarelse, ({body}) => body);

    // @ts-ignore
    fetchMock.get(API.getBesvarelse, besvarelse);
    // fetchMock.get(API.getBesvarelse, ResponseUtils.statusCode(204));

    fetchMock.get(API.getOppfolging, {
        underOppfolging: false, // default false
    });

    fetchMock.get(API.getStatus, {
        erInnlogget: true,
        harGyldigOidcToken: true,
        nivaOidc: 4
    });
};
