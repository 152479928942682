import * as React from 'react';
import alleSporsmal from './sporsmal-alle';
import Sporsmal from './sporsmal';
import { connect } from 'react-redux';
import { Dispatch } from '../types';
import { AppState } from '../ducks/reducer';
import { byttSporsmal, stoppForAViseNyttTips } from '../ducks/side-duck';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { BesvartSporsmal, harBesvartSpm } from '../ducks/sporsmal-duck';
import { lastInnBesvartSporsmal, nullStillAvitteSvar } from '../ducks/svar-duck';
import { Framdrift } from './framdrift';
import { hentInnloggingsstatus } from '../ducks/innloggingsstatus-duck';

interface UrlProps {
    spmId: string;
}

interface StateProps {
    besvarteSporsmal: BesvartSporsmal[];
    sporsmalSomVises: string[];
    avgitteSvar: string[];
    spmIdLagret: string;
    harGyldigInnlogging: boolean;
}

interface DispatchProps {
    doByttSporsmal: (sporsmalId: string, spmErBeesvart: boolean) => void;
    doLastInnBesvartSporsmal: (svar: string[], tips: string | undefined) => void;
    doNullStillAvgitteSvar: () => void;
    doStoppForAViseNyttTips: (stopp: boolean) => void;
    doHentInnloggingsstatus: () => void;
}

interface MatchParams {
    spmId: string;
}

export type SporsmalSideProps = StateProps & DispatchProps & RouteComponentProps<MatchParams> & UrlProps;

class SporsmalSide extends React.Component<SporsmalSideProps, {}> {
    // eslint-disable-next-line
    constructor(props: SporsmalSideProps) {
        super(props);
    }

    componentDidMount(): void {
        const erSisteSporsmal = this.props.match.params.spmId === 'intervju-spm-05';

        if (erSisteSporsmal && this.props.harGyldigInnlogging) {
            this.props.doHentInnloggingsstatus();
        }
        this.props.doStoppForAViseNyttTips(false);
        this.byttSporsmalOgOppdater();
    }

    componentDidUpdate(prevProps: SporsmalSideProps) {
        this.byttSporsmalOgOppdater();
    }

    byttSporsmalOgOppdater() {
        const sporsmalId = this.props.match.params.spmId;
        if (sporsmalId !== this.props.spmIdLagret) {
            this.props.doByttSporsmal(sporsmalId, harBesvartSpm(this.props.besvarteSporsmal, sporsmalId));
            this.oppdaterSporsmal();
        }
    }

    oppdaterSporsmal() {
        const besvartSpm = this.props.besvarteSporsmal.find(
            besvart => besvart.spmId === this.props.match.params.spmId);
        if (besvartSpm !== undefined) {
            this.props.doLastInnBesvartSporsmal(besvartSpm.svar, besvartSpm.tips);
        }
    }

    forrigeSporsmalErBesvart() {
        const {sporsmalSomVises, match, besvarteSporsmal} = this.props;
        const forrigeSpmId = sporsmalSomVises[sporsmalSomVises.indexOf(match.params.spmId) - 1];
        return besvarteSporsmal.map(besvartSpm => besvartSpm.spmId).includes(forrigeSpmId);
    }

    erForsteSporsmal() {
        return this.props.match.params.spmId === this.props.sporsmalSomVises[0];
    }

    render() {
        const {sporsmalSomVises, spmIdLagret} = this.props;
        const {spmId} = this.props.match.params;
        const sporsmal = alleSporsmal.find(spm => spm.id === spmId)!;

        if (this.forrigeSporsmalErBesvart() || this.erForsteSporsmal()) {
            return (
                <React.Fragment>
                    <Framdrift sporsmal={sporsmal} sporsmalSomVises={sporsmalSomVises} lagretSpmId={spmIdLagret}/>
                    <Sporsmal
                        key={spmId}
                        sporsmal={sporsmal}
                        history={this.props.history}
                    />
                </React.Fragment>
            );
        }
        return <Redirect to={'/startside'}/>;
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    besvarteSporsmal: state.sporsmal.besvarteSporsmal,
    sporsmalSomVises: state.sporsmal.sporsmalSomVises,
    avgitteSvar: state.svar.avgitteSvar,
    spmIdLagret: state.side.spmId,
    harGyldigInnlogging: state.innloggingsstatus.harGyldigOidcToken && state.innloggingsstatus.nivaOidc === 4,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doByttSporsmal: (sporsmalId: string, spmErBesvart: boolean) =>
        dispatch(byttSporsmal(sporsmalId, spmErBesvart)),
    doLastInnBesvartSporsmal: (svar: string[], tips: string | undefined) =>
        dispatch(lastInnBesvartSporsmal(svar, tips)),
    doNullStillAvgitteSvar: () => dispatch(nullStillAvitteSvar()),
    doStoppForAViseNyttTips: (stopp: boolean) => dispatch(stoppForAViseNyttTips(stopp)),
    doHentInnloggingsstatus: () => hentInnloggingsstatus()(dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SporsmalSide));
