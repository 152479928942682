import {
    ActionType,
    Handling,
    HentInnloggingsstatusFEILETAction,
    HentInnloggingsstatusOKAction,
    HentInnloggingsstatusPENDINGAction,
} from '../actions';
import { Dispatch } from '../types';
import { fetchThenDispatch } from '../api/fetch-utils';
import { getInnloggingsstatusFetch } from '../api/api';
import { DataElement, Status } from '../api/datalaster';

export interface InnloggingsstatusState extends DataElement {
    erInnlogget: boolean;
    harGyldigOidcToken: boolean;
    nivaOidc: number;
}

export const initialState: InnloggingsstatusState = {
    erInnlogget: false,
    harGyldigOidcToken: false,
    nivaOidc: 0,
    status: Status.IKKE_STARTET,
};

//  Reducer
export default function reducer(
    state: InnloggingsstatusState = initialState,
    action: Handling
): InnloggingsstatusState {
    switch (action.type) {
        case ActionType.HENT_INNLOGGINGSSTATUS_OK:
            return {
                erInnlogget: action.status.erInnlogget,
                harGyldigOidcToken: action.status.harGyldigOidcToken,
                nivaOidc: action.status.nivaOidc,
                status: Status.OK,
            };
        case ActionType.HENT_INNLOGGINGSSTATUS_FEILET:
            return { ...state, status: Status.FEILET };
        case ActionType.HENT_INNLOGGINGSSTATUS_PENDING:
            if (state.status === Status.OK) {
                return { ...state, status: Status.RELOADING };
            }
            return { ...state, status: Status.PENDING };
        default:
            return state;
    }
}

export function hentInnloggingsstatus(): (dispatch: Dispatch) => Promise<void> {
    return fetchThenDispatch<InnloggingsstatusState>(() => getInnloggingsstatusFetch(), {
        ok: hentInnloggingsstatusOK,
        feilet: hentInnloggingsstatusFEILET,
        pending: hentInnloggingsstatusPENDING,
    });
}

function hentInnloggingsstatusOK(status: InnloggingsstatusState): HentInnloggingsstatusOKAction {
    return {
        type: ActionType.HENT_INNLOGGINGSSTATUS_OK,
        status,
    };
}

function hentInnloggingsstatusFEILET(): HentInnloggingsstatusFEILETAction {
    return {
        type: ActionType.HENT_INNLOGGINGSSTATUS_FEILET,
    };
}

function hentInnloggingsstatusPENDING(): HentInnloggingsstatusPENDINGAction {
    return {
        type: ActionType.HENT_INNLOGGINGSSTATUS_PENDING,
    };
}
