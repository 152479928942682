import {
    ActionType, Handling, HentFeaturesFEILETAction, HentFeaturesOKAction, HentFeaturesPENDINGAction,
} from '../actions';
import { Dispatch } from '../types';
import { getFeaturesFetch } from '../api/api';
import { fetchThenDispatch } from '../api/fetch-utils';
import { DataElement, Status } from '../api/datalaster';

export interface Features extends DataElement {
    dummyFeature: boolean;
}

export interface FeatureState extends DataElement, Features {}

export const initialState: FeatureState = {
    dummyFeature: false,
    status: Status.OK,
};

//  Reducer
export default function reducer(state: FeatureState = initialState, action: Handling): FeatureState {
    switch (action.type) {
        case ActionType.HENT_FEATURES_OK:
            return action.features && action.features.jobbsokerkompetanse
                ? { ...action.features.jobbsokerkompetanse, status: Status.OK }
                : state;
        case ActionType.HENT_FEATURES_PENDING:
            return { ...state, status: Status.PENDING };
        case ActionType.HENT_FEATURES_FEILET:
            return { ...state, status: Status.FEILET };
        default:
            return state;
    }
}

export interface AlleFeatures {
    [key: string]: Features;
}

export function hentFeatures(): (dispatch: Dispatch) => Promise<void> {
    return fetchThenDispatch<AlleFeatures>(() => getFeaturesFetch(), {
        ok: hentFeatureOK,
        feilet: hentFeatureFEILET,
        pending: hentFeaturePENDING,
    });
}

function hentFeatureOK(features: AlleFeatures): HentFeaturesOKAction {
    return {
        type: ActionType.HENT_FEATURES_OK,
        features,
    };
}

function hentFeatureFEILET(): HentFeaturesFEILETAction {
    return {
        type: ActionType.HENT_FEATURES_FEILET,
    };
}

function hentFeaturePENDING(): HentFeaturesPENDINGAction {
    return {
        type: ActionType.HENT_FEATURES_PENDING,
    };
}
