import * as React from 'react';
import { connect } from 'react-redux';
import SporsmalModell from '../sporsmal/sporsmal-modell';
import { History } from 'history';
import { AppState } from '../ducks/reducer';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import AlternativContainer from '../alternativ/alternativ-container';
import TipsVisning from '../tips/tipsvisning';
import { Innholdstittel, Undertekst } from 'nav-frontend-typografi';
import { AlertStripeFeil } from 'nav-frontend-alertstriper';
import cls from 'classnames';
import { BesvartSporsmal } from '../ducks/sporsmal-duck';
import { SporsmalBilde } from './sporsmal-bilde';
import TilbakeLink from './tilbake-link';
import NesteLink from './neste-link';
import { erIE } from '../utils/ie-test';

interface OwnProps {
    sporsmal: SporsmalModell;
    history: History;
}

interface StateProps {
    besvarteSporsmal: BesvartSporsmal[];
    paVeiBakover: boolean;
    sporsmalSomVises: string[];
    avgitteSvar: string[];
    erNySide: boolean;
    tips: string | undefined;
    viserNyttTips: boolean;
}

type SporsmalProps = OwnProps & StateProps;

export class Sporsmal extends React.Component<SporsmalProps> {
    // eslint-disable-next-line
    constructor(props: SporsmalProps) {
        super(props);
    }

    componentDidMount() {
        if (!this.props.viserNyttTips) {
            let scrollHeight = 0;
            const header = document.querySelector('.siteheader');
            if (header) {
                scrollHeight = header.getBoundingClientRect().height;
            }
            window.scrollTo(0, scrollHeight);
        }
    }

    render() {
        const {
            sporsmal,
            paVeiBakover,
            sporsmalSomVises,
            erNySide,
            tips,
            avgitteSvar,
            viserNyttTips,
        } = this.props;

        const gjeldendeSpmIndex = sporsmalSomVises.indexOf(sporsmal.id);
        const nesteSpmId = sporsmalSomVises[gjeldendeSpmIndex + 1];
        const forrigeSpmId = sporsmalSomVises[gjeldendeSpmIndex - 1];

        const klassenavn = cls('sporsmal', {
            tilbake: paVeiBakover && !erIE(),
            forstesporsmal: gjeldendeSpmIndex === 0,
            videresporsmal: gjeldendeSpmIndex > 0 && !viserNyttTips && !erIE()
        });

        const harSvar = avgitteSvar.length > 0;

        return (
            <div className="limit limit__sporsmal">
                <section
                    id={'sp-' + sporsmal.id}
                    className={klassenavn}
                    tabIndex={0}
                >
                    <div className={'sporsmal__start'}>
                        <div className="sporsmal__innhold">
                            <SporsmalBilde sporsmal={sporsmal}/>
                            <Innholdstittel className="sporsmal__overskrift blokk-xs" tag="h1">
                                <FormattedHTMLMessage id={sporsmal.id}/>
                            </Innholdstittel>
                            <Undertekst className="sporsmal__ingress" tag="p">
                                <FormattedMessage id={sporsmal.egenUndertekst || sporsmal.type}/>
                            </Undertekst>
                        </div>
                    </div>
                    <AlternativContainer sporsmal={sporsmal}/>
                    <section className="tips" role="alert" aria-live="polite">
                        {viserNyttTips && tips && (
                            <TipsVisning id={tips}/>
                        )}
                    </section>
                    <section
                        className={sporsmal.type === 'skala' ? 'skalavalidering' : 'validering'}
                    >
                        {!harSvar && !erNySide && (
                            <AlertStripeFeil>
                                <FormattedMessage id="feilmelding-mangler-svar"/>
                            </AlertStripeFeil>
                        )}
                    </section>
                    <NesteLink sporsmal={sporsmal} nesteSpmId={nesteSpmId} harSvar={harSvar}/>
                    <TilbakeLink sporsmal={sporsmal} forrigeSpmId={forrigeSpmId} history={this.props.history}/>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    besvarteSporsmal: state.sporsmal.besvarteSporsmal,
    paVeiBakover: state.side.paVeiBakover,
    sporsmalSomVises: state.sporsmal.sporsmalSomVises,
    erNySide: state.side.erNySide,
    tips: state.svar.tips,
    avgitteSvar: state.svar.avgitteSvar,
    viserNyttTips: state.svar.viserNyttTips
});

export default connect(mapStateToProps)(Sporsmal);
