import * as React from 'react';

interface OwnProps {
    betingelse: boolean;
    children: React.ReactElement<any>; // tslint:disable-line:no-any
}

export default function VisibleIf({ betingelse, children }: OwnProps) {
    return betingelse === true ? children : null;
}
