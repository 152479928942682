import * as React from 'react';
import SporsmalModell from './sporsmal-modell';
import { FormattedMessage } from 'react-intl';
import { Sidetype } from '../utils/konstanter';
import { Dispatch } from '../types';
import { byttSporsmal, starteSvar, stoppForAViseNyttTips } from '../ducks/side-duck';
import { BesvartSporsmal, leggTilBesvartSporsmal } from '../ducks/sporsmal-duck';
import { connect } from 'react-redux';
import { AppState } from '../ducks/reducer';
import { nullStillAvitteSvar, visNyttTips } from '../ducks/svar-duck';
import cls from 'classnames';
import { erIE } from '../utils/ie-test';
import { genererKulepunkter, genererOppsummering } from '../ducks/tilbakemelding-duck';
import { finnRaadSomSkalVises } from '../ducks/raad-duck';
import { locationStateConstants } from '../routes';
import LinkMedQueryParams from '../link-med-query-params';
import LoggInnModal from '../resultat/lagring/logg-inn-modal';

interface DispatchProps {
    doLeggTilBesvartSporsmal: (spmId: string, svar: string[], tips: string | undefined) => void;
    ikkeNySideLenger: () => void;
    doVisNyttTips: (visTips: boolean) => void;
    doStoppForAViseNyttTips: (stopp: boolean) => void;
    doByttSporsmal: (nesteSpmId: string) => void;
    doNullStillAvgitteSvar: () => void;
    doGenererKulepunkter: (besvarteSporsmal: BesvartSporsmal[]) => void;
    doGenererOppsummering: (besvarteSporsmal: BesvartSporsmal[]) => void;
    doFinnRaadSomSkalVises: (besvarteSporsmal: BesvartSporsmal[]) => void;
}

interface OwnProps {
    sporsmal: SporsmalModell;
    nesteSpmId: string;
    harSvar: boolean;
}

interface StateProps {
    avgitteSvar: string[];
    skalStoppeForAViseNyttTips: boolean;
    tips: string | undefined;
    underOppfolging: boolean;
    besvarteSporsmal: BesvartSporsmal[];
    harGyldigInnlogging: boolean;
}

interface State {
    modalIsOpen: boolean;
}

type NesteLinkProps = OwnProps & DispatchProps & StateProps;

export class NesteLink extends React.Component<NesteLinkProps, State> {

    static setLoading() {
        const body = document.querySelector('body');
        if (body && !body.classList.contains('loading')) {
            body.classList.add('loading');
        }
    }

    constructor(props: NesteLinkProps) {
        super(props);
        this.state = {
            modalIsOpen: false
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    render() {
        const { modalIsOpen } = this.state;

        const {
            sporsmal,
            nesteSpmId,
            harSvar,
            tips,
            ikkeNySideLenger,
            doLeggTilBesvartSporsmal,
            avgitteSvar,
            skalStoppeForAViseNyttTips,
            doVisNyttTips,
            doStoppForAViseNyttTips,
            doByttSporsmal,
            doNullStillAvgitteSvar,
            underOppfolging,
            besvarteSporsmal,
            doGenererKulepunkter,
            doGenererOppsummering,
            harGyldigInnlogging
        } = this.props;

        const nesteUrl = sporsmal.erSisteSpm
            ? '/' + Sidetype.RESULTAT + (underOppfolging && harGyldigInnlogging ? '/lagre' : '')
            : '/' + Sidetype.KARTLEGGING + '/' + nesteSpmId
        ;

        const locationstate = {[locationStateConstants.kommerFraSisteSpm] : true};
        const state = sporsmal.erSisteSpm ? locationstate : undefined;

        return (
            <>
                <LinkMedQueryParams
                    url={nesteUrl}
                    state={state}
                    className={cls({
                        sporsmal__knapp: !sporsmal.erSisteSpm && !erIE(),
                        'sporsmal__knapp--IE': !sporsmal.erSisteSpm && erIE(),
                        'knapp--send': sporsmal.erSisteSpm && !erIE(),
                        'knapp--send--IE': sporsmal.erSisteSpm && erIE(),
                        erBesvart: harSvar
                    })}
                    key="besvar"
                    onClick={(e: any) => { // tslint:disable-line
                        if (!harSvar) {
                            e.preventDefault();
                            ikkeNySideLenger();
                        } else {
                            if (skalStoppeForAViseNyttTips) {
                                e.preventDefault();
                                doVisNyttTips(true);
                                doStoppForAViseNyttTips(false);
                            } else {
                                doLeggTilBesvartSporsmal(sporsmal.id, avgitteSvar, tips);
                                doByttSporsmal(nesteSpmId);
                                doNullStillAvgitteSvar();
                                if (sporsmal.erSisteSpm) {
                                    doGenererKulepunkter(besvarteSporsmal);
                                    doGenererOppsummering(besvarteSporsmal);
                                    this.props.doFinnRaadSomSkalVises(this.props.besvarteSporsmal);
                                    if (underOppfolging && !harGyldigInnlogging) {
                                        e.preventDefault();
                                        this.openModal();
                                    } else {
                                        NesteLink.setLoading();
                                    }
                                }
                            }
                        }
                    }}
                >
                    <span className="gjemt">
                        {sporsmal.erSisteSpm ? (
                            <FormattedMessage id="send-inn"/>
                        ) : (
                            <FormattedMessage id="fortsett-knapp"/>
                        )}
                    </span>
                </LinkMedQueryParams>
                <LoggInnModal
                    ingress="siste-sporsmal-logg-inn-lagringsmelding"
                    onClose={() => this.closeModal()}
                    modalIsOpen={modalIsOpen}
                />
            </>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    avgitteSvar: state.svar.avgitteSvar,
    skalStoppeForAViseNyttTips: state.side.skalStoppeForAViseNyttTips,
    tips: state.svar.tips,
    underOppfolging: state.oppfolging.underOppfolging,
    besvarteSporsmal: state.sporsmal.besvarteSporsmal,
    harGyldigInnlogging: state.innloggingsstatus.harGyldigOidcToken && state.innloggingsstatus.nivaOidc === 4
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doLeggTilBesvartSporsmal: (spmId: string, svar: string[], tips: string | undefined) => {
        dispatch(leggTilBesvartSporsmal(spmId, svar, tips));
    },
    doByttSporsmal: (nesteSpmId: string) => dispatch(byttSporsmal(nesteSpmId, false)),
    ikkeNySideLenger: () => dispatch(starteSvar()),
    doStoppForAViseNyttTips: (stopp: boolean) => dispatch(stoppForAViseNyttTips(stopp)),
    doVisNyttTips: (visTips: boolean) => dispatch(visNyttTips(visTips)),
    doNullStillAvgitteSvar: () => dispatch(nullStillAvitteSvar()),
    doGenererKulepunkter: (besvarteSporsmal: BesvartSporsmal[]) => dispatch(genererKulepunkter(besvarteSporsmal)),
    doGenererOppsummering: (besvarteSporsmal: BesvartSporsmal[]) => dispatch(
        genererOppsummering(besvarteSporsmal)
    ),
    doFinnRaadSomSkalVises: (besvarteSporsmal: BesvartSporsmal[]) => dispatch(finnRaadSomSkalVises(besvarteSporsmal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NesteLink);
