import * as React from 'react';
import RaadVisning from './raad/raad-visning';
import SporsmalModell from '../sporsmal/sporsmal-modell';
import { BrukerRaad } from './raad/raad-modell';
import { Sidetype } from '../utils/konstanter';
import Loader from './loader';
import { Ingress, Innholdstittel, Sidetittel, Undertittel, Normaltekst } from 'nav-frontend-typografi';
import Lenkepanel from 'nav-frontend-lenkepanel';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { AppState } from '../ducks/reducer';
import { Dispatch } from '../types';
import { nullStillStore } from '../ducks/side-duck';
import { connect } from 'react-redux';
import VisibleIf from '../utils/visible-if';
import LagreResultaterKnapp from './lagring/lagre-resultater-knapp';
import Kulepunkter from './kulepunkt/kulepunkter';
import OppsummeringVisning from './oppsummering/oppsummering-visning';
import { Kulepunkt } from './kulepunkt/kulepunkter-generering';
import { Oppsummering } from './oppsummering/oppsummering-generering';
import { raadTilBruker } from './raad/raad-selectors';
import cls from 'classnames';
import { clearLocalStorage } from '../utils/persistentReducer';
import { polyfill } from 'smoothscroll-polyfill';
import LinkMedQueryParams from '../link-med-query-params';
const { location } = window;

polyfill();

export const viserKulepunkter = (kulepunkter: Kulepunkt[]): boolean => kulepunkter.length >= 2;
export const viserOppsummering = (kulepunkter: Kulepunkt[], oppsummeringId: string): boolean => {
    if (viserKulepunkter(kulepunkter)) {
        return false;
    } else if (!oppsummeringId) {
        return false;
    }
    return true;
};

const headerBilde = require('../ikoner/resultat/header_resultatside.svg');

interface OwnProps {
    erAdmin: boolean;
    kommerFraRaadside: boolean;
}

interface StateProps {
    alleSporsmal: SporsmalModell[];
    brukerRaad: BrukerRaad[];
    harLagretInnevaerendeKartleggingIDatabasen: boolean;
    kulepunkter: Kulepunkt[];
    oppsummering: Oppsummering;
    underOppfolging: boolean;
    harGyldigOidcToken: boolean;
}

interface DispatchProps {
    doNullStillStore: () => void;
}

interface State {
    windowSize: number;
}

type ResultatProps = OwnProps & StateProps & DispatchProps;

export class Resultat extends React.Component<ResultatProps, State> {

    constructor(props: ResultatProps) {
        super(props);
        this.state = {
            windowSize: window.innerWidth
        };
        this.handleWindowSize = this.handleWindowSize.bind(this);
    }

    handleWindowSize() {
        this.setState({
            windowSize: window.innerWidth
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSize);
    }

    componentDidMount() {
        let scrollHeight = 0;
        const header = document.querySelector('.siteheader');
        if (header) {
            scrollHeight = header.getBoundingClientRect().height;
        }
        window.scrollTo(0, scrollHeight);
        const body = document.querySelector('body');
        if (body && body.classList.contains('loading')) {
            setTimeout(() => body.classList.remove('loading'), 5500);
        }
        window.addEventListener('resize', this.handleWindowSize);
        const erMobil = this.state.windowSize < 768;
        const raadOverskrift = document.querySelector('.raadliste__overskrift');

        if (raadOverskrift && this.props.kommerFraRaadside && erMobil) {
            raadOverskrift.scrollIntoView({
                block: 'start',
                behavior: 'smooth'
            });
        }
    }

    render() {
        const {
            alleSporsmal, doNullStillStore, brukerRaad,
            erAdmin, kulepunkter, oppsummering, underOppfolging,
            harLagretInnevaerendeKartleggingIDatabasen, harGyldigOidcToken
        }: ResultatProps = this.props;
        const startPaNytt = '/' + Sidetype.KARTLEGGING + '/' + alleSporsmal[0].id;
        if (brukerRaad.length === 0) {
            return null;
        }
        const raadSomSkalVises = erAdmin ? brukerRaad : raadTilBruker(brukerRaad);

        const underOppfolgingOgLagret = harLagretInnevaerendeKartleggingIDatabasen && underOppfolging;
        const ikkeUnderOppfolgingOgIkkeLagret =
            harGyldigOidcToken && !underOppfolging && !harLagretInnevaerendeKartleggingIDatabasen;

        const klassenavn = cls('lenker__innhold', {
            fullbredde: underOppfolging
        });

        return (
            <>
                <div className="resultat__header">
                    <div className="header limit">
                        <div className="header__tekst">
                            <Sidetittel className="resultat__overskrift" tag="h1">
                                <FormattedHTMLMessage id="overskrift-raad"/>
                            </Sidetittel>
                            <Ingress className="resultat__ingress">
                                <FormattedHTMLMessage id="ingress-raad"/>
                            </Ingress>
                        </div>
                        <div className="header__bilde">
                            <img
                                src={headerBilde}
                                alt=""
                                className="headerbilde"
                                role="img"
                            />
                        </div>
                    </div>
                </div>
                <div className="resultat limit">
                    <Loader/>
                    <div className="resultat__innhold">
                        <VisibleIf betingelse={viserKulepunkter(kulepunkter) || erAdmin}>
                            <Kulepunkter erAdmin={erAdmin}/>
                        </VisibleIf>
                        <VisibleIf
                            betingelse={viserOppsummering(kulepunkter, oppsummering.tekstId) || (erAdmin && !!oppsummering.tekstId)}
                        >
                            <OppsummeringVisning/>
                        </VisibleIf>
                        <section>
                            <Innholdstittel className="raadliste__overskrift">
                                <FormattedMessage id="raadliste-overskrift"/>
                            </Innholdstittel>
                            <div className="resultat__raadliste">
                                {raadSomSkalVises.map((br, i) => (
                                    <RaadVisning key={i} brukerRaad={br} index={i}/>
                                ))}
                            </div>
                        </section>
                        <section className="resultat__knapper">
                            <LagreResultaterKnapp/>
                            <LinkMedQueryParams
                                url={startPaNytt}
                                state={{prevPath: location.pathname}}
                                className="knapp knapp--full"
                                onClick={() => {
                                    clearLocalStorage();
                                    doNullStillStore();
                                }}
                            >
                                <FormattedMessage id="start-pa-nytt-knapp"/>
                            </LinkMedQueryParams>
                            <VisibleIf betingelse={underOppfolgingOgLagret}>
                                <p className="typo-etikett-liten resultat_lagringsmelding">
                                    <FormattedMessage id="resultatside-lagre-underoppfolging"/>
                                </p>
                            </VisibleIf>
                            <VisibleIf betingelse={ikkeUnderOppfolgingOgIkkeLagret}>
                                <p className="typo-etikett-liten resultat_lagringsmelding">
                                    <FormattedMessage id="resultatside-lagre-ikkeunderoppfolging"/>
                                </p>
                            </VisibleIf>
                        </section>
                    </div>
                </div>

                <div className="resultat__lenker">
                    <div className="limit">
                        <Innholdstittel className="lenker__overskrift">
                            <FormattedMessage id="resultat-lenker-overskrift"/>
                        </Innholdstittel>
                        <div className={klassenavn}>

                            <Lenkepanel
                                href="/veiviserarbeidssoker/?situasjon=ung-lite-erfaring/"
                                tittelProps="element"
                                className={cls({bredde50: underOppfolging})}
                            >
                                <Undertittel className="lenke__tittel">
                                    <FormattedMessage id="resultat-ga-til-veiviser-tittel"/>
                                </Undertittel>
                                <Normaltekst className="lenke__ingress">
                                    <FormattedMessage id="resultat-ga-til-veiviser-ingress"/>
                                </Normaltekst>
                            </Lenkepanel>

                            <VisibleIf betingelse={underOppfolging}>
                                <Lenkepanel
                                    href="/veientilarbeid/"
                                    tittelProps="element"
                                    className="bredde50"
                                >
                                    <Undertittel className="lenke__tittel">
                                        <FormattedMessage id="resultat-ga-til-veien-til-arbeid-tittel"/>
                                    </Undertittel>
                                    <Normaltekst className="lenke__ingress">
                                        <FormattedMessage id="resultat-ga-til-veien-til-arbeid-ingress"/>
                                    </Normaltekst>
                                </Lenkepanel>
                            </VisibleIf>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    alleSporsmal: state.sporsmal.alleSporsmal,
    brukerRaad: state.raad.brukerRaad,
    harLagretInnevaerendeKartleggingIDatabasen: state.api.harLagretInnevaerendeKartleggingIDatabasen,
    kulepunkter: state.tilbakemelding.kulepunkter,
    oppsummering: state.tilbakemelding.oppsummering,
    underOppfolging: state.oppfolging.underOppfolging,
    harGyldigOidcToken: state.innloggingsstatus.harGyldigOidcToken,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doNullStillStore: () => dispatch(nullStillStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Resultat);
