import * as React from 'react';
import SporsmalSide from './sporsmal/sporsmal-side';
import ResultatSide from './resultat/resultat-side';
import RaadSide from './raadside/raadside';
import Startside from './startside/startside';
import { Switch, Route, withRouter, match } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import RedirectForsteSporsmal from './redirect-forste-sporsmal';
import LagreResultater from './resultat/lagring/lagre-resultater';
import { Sidetype } from './utils/konstanter';
import RedirectStartside from './redirect-startside';
import { connect } from 'react-redux';
import { AppState } from './ducks/reducer';

export const URL_LAGRE = `/${Sidetype.RESULTAT}/lagre`;
export const URL_ADMIN = `/${Sidetype.RESULTAT}/admin`;

interface LocationState {
    kommerFraSisteSpm: boolean;
    kommerFraLagre: boolean;
    kommerFraRaadside: boolean;
}

interface LocationStateConstants {
    kommerFraSisteSpm: string;
    kommerFraLagre: string;
    kommerFraRaadside: string;
}

export const locationStateConstants: LocationStateConstants = {
    kommerFraSisteSpm: 'kommerFraSisteSpm',
    kommerFraLagre: 'kommerFraLagre',
    kommerFraRaadside: 'kommerFraRaadside',
};

interface StateProps {
    harLagretInnevaerendeKartleggingIDatabasen: boolean;
}

interface Match {
    match: match<{spmId: string}>
}

type RouteProps = RouteComponentProps<any, any, LocationState> & StateProps; // tslint:disable-line:no-any

const Routes = (props: RouteProps) => {
    return (
        <Switch location={props.location}>
            <Route
                path={`/${Sidetype.KARTLEGGING}/:spmId`}
                component={ ({match}: Match) => <SporsmalSide key={match.params.spmId}/> }
            />
            <Route
                path="/start"
                component={RedirectForsteSporsmal}
            />
            <Route
                exact={true}
                path={`/${Sidetype.START}`}
                component={Startside}
            />
            <Route path={URL_LAGRE} component={LagreResultater}/>
            <Route path={URL_ADMIN} component={ResultatSide}/>
            <Route path={`/${Sidetype.RESULTAT}/raad/:raadId/:index`} component={RaadSide}/>
            <Route
                path={`/${Sidetype.RESULTAT}`}
                component={ResultatSide}
            />
            <Route
                exact={true}
                path="/*"
                component={RedirectStartside}
            />
        </Switch>
    );
}

const mapStateToProps = (state: AppState): StateProps => ({
    harLagretInnevaerendeKartleggingIDatabasen: state.api.harLagretInnevaerendeKartleggingIDatabasen,
});

export default withRouter(connect(mapStateToProps)(Routes));
