import { BesvartSporsmal } from '../../ducks/sporsmal-duck';
import { getAlleSvarteAlternativer } from '../raad/raad-generering';

export interface Oppsummering {
    tekstId: string;
}

const beregnOppsummering = (avgitteSvar: string[]): Oppsummering => {
    if (avgitteSvar.length >= 0) {
        if (avgitteSvar.includes('soke-svar-0101')) {
            return {tekstId: 'oppsummering1'};
        } else if (avgitteSvar.includes('soke-svar-0102')) {
            if (avgitteSvar.includes('soke-svar-0201')) {
                return {tekstId: 'oppsummering2'};
            } else if (avgitteSvar.includes('soke-svar-0202')) {
                if (avgitteSvar.includes('soke-svar-0301')) {
                    return {tekstId: 'oppsummering3'};
                } else if (avgitteSvar.includes('soke-svar-0302')) {
                    return {tekstId: 'oppsummering4'};
                } else if (avgitteSvar.includes('soke-svar-0303')) {
                    return {tekstId: 'oppsummering5'};
                }
            } else if (avgitteSvar.includes('soke-svar-0203')) {
                if (avgitteSvar.includes('soke-svar-0301')) {
                    return {tekstId: 'oppsummering6'};
                } else if (
                    avgitteSvar.includes('soke-svar-0302')) {
                    return {tekstId: 'oppsummering7'};
                } else if (
                    avgitteSvar.includes('soke-svar-0303')) {
                    return {tekstId: 'oppsummering8'};
                }
            }
        } else if (
        avgitteSvar.includes('soke-svar-0103') ||
        avgitteSvar.includes('soke-svar-0104') ||
        avgitteSvar.includes('soke-svar-0105')) {
            if (avgitteSvar.includes('soke-svar-0201')) {
                return {tekstId: 'oppsummering9'};
            } else if (avgitteSvar.includes('soke-svar-0202')) {
                if (avgitteSvar.includes('soke-svar-0301')) {
                    return {tekstId: 'oppsummering10'};
                } else if (avgitteSvar.includes('soke-svar-0302')) {
                    return {tekstId: 'oppsummering11'};
                } else if (avgitteSvar.includes('soke-svar-0303')) {
                    return {tekstId: 'oppsummering12'};
                }
            } else if (avgitteSvar.includes('soke-svar-0203')) {
                if (avgitteSvar.includes('soke-svar-0301')) {
                    return {tekstId: 'oppsummering13'};
                } else if (avgitteSvar.includes('soke-svar-0302')) {
                    return {tekstId: 'oppsummering14'};
                } else if (avgitteSvar.includes('soke-svar-0303')) {
                    return {tekstId: 'oppsummering15'};
                }
            }
        }
        return {tekstId: ''};
    }
    return {tekstId: ''};
};

export const genererKonstruktivOppsummering = (besvarteSporsmal: BesvartSporsmal[]): Oppsummering => {
    const sokeSpmBesvart = besvarteSporsmal.filter(
        besvartSpm =>
            besvartSpm.spmId === 'soke-spm-01' ||
            besvartSpm.spmId === 'soke-spm-02' ||
            besvartSpm.spmId === 'soke-spm-03'
    );
    return beregnOppsummering(getAlleSvarteAlternativer(sokeSpmBesvart));
};
