import * as React from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import SporsmalModell from './sporsmal-modell';
import { Dispatch } from '../types';
import { FormattedMessage } from 'react-intl';
import { Sidetype } from '../utils/konstanter';
import { nullStillAvitteSvar } from '../ducks/svar-duck';
import { byttSporsmal } from '../ducks/side-duck';
import { leggTilBesvartSporsmal } from '../ducks/sporsmal-duck';
import { AppState } from '../ducks/reducer';
import VisibleIf from '../utils/visible-if';
import cls from 'classnames';
import LinkMedQueryParams from '../link-med-query-params';

interface StateProps {
    avgitteSvar: string[];
    tips: string | undefined;
}

interface DispatchProps {
    doByttSporsmal: (nesteSpmId: string) => void;
    doNullStillAvgitteSvar: () => void;
    doLeggTilBesvartSporsmal: (spmId: string, svar: string[], tips: string | undefined) => void;
}

interface OwnProps {
    sporsmal: SporsmalModell;
    forrigeSpmId: string;
    history: History;
}

type TilbakeLinkProps = StateProps & DispatchProps & OwnProps;

export class TilbakeLink extends React.Component<TilbakeLinkProps> {
    // eslint-disable-next-line
    constructor(props: TilbakeLinkProps) {
        super(props);
    }

    kommerFraResultatside() {
        const location = this.props.history.location;
        return (location.state && location.state.prevPath && location.state.prevPath.includes('resultatside'));
    }

    render() {
        const {
            sporsmal,
            forrigeSpmId,
            doByttSporsmal,
            doNullStillAvgitteSvar,
            doLeggTilBesvartSporsmal,
            avgitteSvar,
            tips,
            history,
        } = this.props;

        const tilbakeUrl = sporsmal.erForsteSpm
            ? '/' + Sidetype.START
            : '/' + Sidetype.KARTLEGGING + '/' + forrigeSpmId;

        return (
            <VisibleIf betingelse={!this.kommerFraResultatside()}>
                <LinkMedQueryParams
                    url={tilbakeUrl}
                    className={
                        'sporsmal__knapp-tilbake ' + cls({'forsteSpm': sporsmal.erForsteSpm, 'ikkeForsteSpm': !sporsmal.erForsteSpm})
                    }
                    onClick={(e: any) => { // tslint:disable-line
                        e.preventDefault();
                        doByttSporsmal(forrigeSpmId);
                        doNullStillAvgitteSvar();
                        doLeggTilBesvartSporsmal(sporsmal.id, avgitteSvar, tips);
                        history.goBack();
                    }}
                >
                    {sporsmal.erForsteSpm ? (
                        <FormattedMessage id="forrige-knapp-start"/>
                    ) : (
                        <FormattedMessage id="forrige-knapp"/>
                    )}
                </LinkMedQueryParams>
            </VisibleIf>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    avgitteSvar: state.svar.avgitteSvar,
    tips: state.svar.tips,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doByttSporsmal: (nesteSpmId: string) => dispatch(byttSporsmal(nesteSpmId, false)),
    doNullStillAvgitteSvar: () => dispatch(nullStillAvitteSvar()),
    doLeggTilBesvartSporsmal: (spmId: string, svar: string[], tips: string | undefined) => {
        dispatch(leggTilBesvartSporsmal(spmId, svar, tips));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(TilbakeLink);
