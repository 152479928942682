import { combineReducers } from 'redux';
import persistent, { localStorageEntry } from '../utils/persistentReducer';
import sideReducer, {
    SideState,
    initialState as initialSideState
} from './side-duck';
import raadReducer, { RaadState, initialState as initialRaadState } from './raad-duck';
import sporsmalReducer, { SporsmalState, initialState as initialSporsmalState } from './sporsmal-duck';
import svarReducer, { SvarState } from './svar-duck';
import apiReducer, { ApiState } from './api-duck';
import featuresReducer, { FeatureState } from './feature-duck';
import innloggingsstatusReducer, { InnloggingsstatusState } from './innloggingsstatus-duck';
import tilbakemeldingReducer, {
    TilbakemeldingState,
    initialState as initialTilbakemeldingState
} from './tilbakemelding-duck';
import oppfolgingReducer, { OppfolgingState } from './oppfolging-duck';
import { tekstnokkelDuck, TekstnokkelState } from './tekstnokkel-duck';
const { location } = window;

export interface AppState {
    side: SideState;
    raad: RaadState;
    sporsmal: SporsmalState;
    svar: SvarState;
    api: ApiState;
    feature: FeatureState;
    innloggingsstatus: InnloggingsstatusState;
    tilbakemelding: TilbakemeldingState;
    oppfolging: OppfolgingState;
    tekstnokkel: TekstnokkelState;
}

export default combineReducers<AppState>({
    side: persistent(localStorageEntry.sideState, location, sideReducer, initialSideState),
    raad: persistent(localStorageEntry.raadState, location, raadReducer, initialRaadState),
    sporsmal: persistent(localStorageEntry.sporsmalState, location, sporsmalReducer, initialSporsmalState),
    svar: svarReducer,
    api: apiReducer,
    feature: featuresReducer,
    innloggingsstatus: innloggingsstatusReducer,
    tilbakemelding: persistent(
        localStorageEntry.tilbakemeldingState,
        location, tilbakemeldingReducer,
        initialTilbakemeldingState
    ),
    oppfolging: oppfolgingReducer,
    tekstnokkel: tekstnokkelDuck.reducer,
});
