/* tslint:disable:max-line-length */
export default { 'nb': { 
'feilmelding-lagre': 'Resultatene dine ble ikke lagret på grunn av en teknisk feil. Vi beklager, du kan prøve igjen senere.',
'feilmelding-tekniskfeil': 'Det har dessverre oppstått en teknisk feil hos oss. Vennligst forsøk igjen om en liten stund.',
'ingress-raad': 'Ut fra det du har svart, har vi noen jobbsøkertips til deg.',
'kulepunkt-prioritet10': 'Du er usikker på hvordan du forbereder deg til intervju, men du gjør flere ting riktig',
'kulepunkt-prioritet11': 'Du har gjort ting som øker sjansene dine for å få jobb',
'kulepunkt-prioritet12': 'Du gjør noen justeringer i søknaden for hver stilling du søker',
'kulepunkt-prioritet13': 'Du gjør noen justeringer i CV-en din når du søker jobber',
'kulepunkt-prioritet14': 'Du leter etter jobber flere steder',
'kulepunkt-prioritet15': 'Du har gjort flere ting som øker sjansene dine for å få en jobb',
'kulepunkt-prioritet16': 'Du gjør en del forberedelser før du skal på intervju',
'kulepunkt-prioritet1': 'Du tilpasser hver enkelt søknad til stillingen du søker på',
'kulepunkt-prioritet2': 'Du er åpen for ulike former for ansettelser',
'kulepunkt-prioritet3': 'Du forbereder deg godt til intervju',
'kulepunkt-prioritet4': 'Du søker jobber innenfor et større geografisk område',
'kulepunkt-prioritet5': 'Du søker etter jobber på flere forskjellige måter',
'kulepunkt-prioritet6': 'Du har registrert CV-en din flere steder',
'kulepunkt-prioritet7': 'Du leter etter jobber på mange forskjellige måter',
'kulepunkt-prioritet8': 'Du søker på flere typer jobber',
'kulepunkt-prioritet9': 'Du skreddersyr CV-en din til hver stilling du søker',
'kulepunkter-overskrift': 'Dette gjør du bra',
'oppsummering-overskrift': 'Oppsummert',
'oppsummering10': 'Du sier at du har blitt innkalt til noen intervjuer, men at du ennå ikke har fått et jobbtilbud. Det lurt å forberede seg godt til intervjuet og evaluere innsatsen etterpå.',
'oppsummering11': 'Du sier at du har fått noen jobbtilbud det siste året, men at du fortsatt er jobbsøker. All erfaring er god erfaring, og gir påfyll på CV-en.',
'oppsummering12': 'Du sier at du har fått flere jobbtilbud det siste året, men at du fortsatt er jobbsøker. All erfaring er god erfaring, og gir påfyll på CV-en.',
'oppsummering13': 'Du sier at du har blitt innkalt til flere intervjuer, men at du ennå ikke har fått et jobbtilbud. Det lurt å forberede seg godt til intervjuet og evaluere innsatsen etterpå.',
'oppsummering14': 'Du sier at du har fått noen jobbtilbud det siste året, men at du fortsatt er jobbsøker. All erfaring er god erfaring, og gir påfyll på CV-en.',
'oppsummering15': 'Du sier at du har fått flere jobbtilbud det siste året, men at du fortsatt er jobbsøker. All erfaring er god erfaring, og gir påfyll på CV-en.',
'oppsummering1': 'Du sier at du ikke har søkt noen jobber det siste året. Det er lurt å komme i gang med jobbsøkingen så fort som mulig.',
'oppsummering2': 'Du sier at du har søkt på noen jobber, men at du ennå ikke har blitt innkalt på intervju. Det er lurt å jobbe godt med søknaden og CV-en.',
'oppsummering3': 'Du sier at du har blitt innkalt til noen intervjuer, men at du ennå ikke har fått et jobbtilbud. Det er lurt å forberede seg godt til intervjuet og evaluere innsatsen etterpå.',
'oppsummering4': 'Du sier at du har fått noen jobbtilbud det siste året, men er fortsatt jobbsøker. All erfaring er god erfaring, og gir påfyll på CV-en.',
'oppsummering5': 'Du sier at du har fått flere jobbtilbud det siste året, men at du fortsatt er jobbsøker. All erfaring er god erfaring, og gir påfyll på CV-en.',
'oppsummering6': 'Du sier at du har blitt innkalt til flere intervjuer, men at du ennå ikke har fått et jobbtilbud. Det lurt å forberede seg godt til intervjuet og evaluere innsatsen etterpå.',
'oppsummering7': 'Du sier at du har fått noen jobbtilbud det siste året, men at du fortsatt er jobbsøker. All erfaring er god erfaring, og gir påfyll på CV-en.',
'oppsummering8': 'Du sier at du har fått flere jobbtilbud det siste året, men at du fortsatt er jobbsøker. All erfaring er god erfaring, og gir påfyll på CV-en.',
'oppsummering9': 'Du sier at du har søkt på flere jobber, men at du ennå ikke har blitt innkalt på intervju. Det er lurt å jobbe godt med søknaden og CV-en.',
'overskrift-raad': 'Dine jobbsøkertips',
'1-1': 'Mange tenker for smalt om sin egen kompetanse. Se tips til deg.',
'2-12': 'Du sier at du bare søker en type stillinger. Se tips til deg.',
'2-13': 'Du sier at du ikke søker jobber andre steder i landet. Se tips til deg.',
'2-2': 'Du sier at du ikke har opprettet et automatisk stillingssøk. Se tips til deg.',
'2-8': 'Du sier at du ikke bruker nettverket ditt når du søker jobber. Se tips til deg.',
'3-1': 'Du sier at du har lite erfaring som jobbsøker. Vi vil oppfordre deg til å jobbe godt med søknadene dine. Se tips til deg.',
'3-2': 'Mange glemmer å tilpasse CV-en til de stillingene de søker på. Se tips til deg.',
'3-8': 'Du sier du ikke har sendt en åpen søknad. Se tips til deg.',
'3-9': 'Du sier at du ikke har forsøkt å kontakte en arbeidsgiver direkte. Se tips til deg.',
'4-2': 'Mange synes at intervju kan være litt skummelt og vanskelig. Det hjelper å forberede seg godt. Se tips til deg.',
'4-6': 'Mange glemmer at førsteinntrykket er viktig når du møter på intervju. Se tips til deg.',
'5-1': 'Mange trenger en plan for å holde orden på jobbsøkeraktivitetene sine. Se tips til deg.',
'raadliste-overskrift': 'Dette kan du gjøre bedre',
'resultat-avbryt-knapp': 'Avbryt',
'resultat-ga-til-veien-til-arbeid-ingress': 'Her finner du aktivitetsplanen og informasjon om meldekort, dagpenger og mulighetene i arbeidsmarkedet.',
'resultat-ga-til-veien-til-arbeid-tittel': 'Veien til arbeid',
'resultat-ga-til-veiviser-ingress': 'Se alle tipsene NAV har om søknad, CV, intervju og hvordan du finner jobbene.',
'resultat-ga-til-veiviser-tittel': 'Alle jobbsøkertips',
'resultat-lagreknapp-tekst1': 'Ønsker du å lagre resultatene?',
'resultat-lagreknapp-tekst2': 'Hvis du får oppfølging fra NAV, kan du lagre resultatene dine.',
'resultat-lagreknapp': 'Logg inn og lagre',
'resultat-lenker-overskrift': 'Hva kan du gjøre nå?',
'resultat-logg-inn-knapp': 'Logg inn',
'resultat-logg-inn-lagringsmelding': 'Du må logge inn med BankID, BankID på mobil, Buypass eller Commfides. Resultatene dine vil bli delt med NAV for å gi bedre oppfølging.',
'resultatside-lagre-ikkeunderoppfolging': 'Resultatene dine kan ikke lagres ettersom du ikke er under oppfølging fra NAV.',
'resultatside-lagre-underoppfolging': 'Resultatet ditt har blitt automatisk lagret og delt med NAV, fordi du får oppfølging fra NAV. Opplysningene blir anonymisert og fjernet fra profilen din når oppfølgingen er avsluttet.',
'se-alle-raad': 'Se alle råd',
'start-pa-nytt-knapp': 'Start på nytt',
'tilbake-til-resultatsiden-knapp': 'Tilbake',
'cv-spm-01': 'Hvor har du registrert CV-en din?',
'cv-spm-02': 'Hvor synlig er du for en arbeidsgiver som leter etter deg?',
'cv-spm-03': 'Tilpasser du CV-en til hver enkelt stillingsannonse?',
'cv-spm-04': 'Hvor etterspurt er kompetansen din på arbeidsmarkedet?',
'cv-spm-05': 'Hvilken erfaring har du med å søke jobber?',
'cv-svar-0101': 'nav.no',
'cv-svar-0102': 'Finn.no',
'cv-svar-0103': 'LinkedIn',
'cv-svar-0104': 'Bemanningsbyråer ol.',
'cv-svar-0105': 'På nettsidene til bedrifter',
'cv-svar-0106': 'Jobb-apper',
'cv-svar-0107': 'Andre steder',
'cv-svar-0108': 'Jeg har ikke registrert CV-en min',
'cv-svar-0201': 'Lite synlig',
'cv-svar-0202': 'Ganske synlig',
'cv-svar-0203': 'Veldig synlig',
'cv-svar-0301': 'Skreddersyr hver CV',
'cv-svar-0302': 'Gjør noen tilpasninger',
'cv-svar-0303': 'Sender samme CV til alle',
'cv-svar-0401': '1',
'cv-svar-0402': '2',
'cv-svar-0403': '3',
'cv-svar-0404': '4',
'cv-svar-0405': '5',
'cv-svar-0501': 'Fått tilbud om jobb gjennom nettverket mitt',
'cv-svar-0502': 'Tatt direkte kontakt med en arbeidsgiver',
'cv-svar-0503': 'Sendt søknad på utlyst stilling',
'cv-svar-0504': 'Sendt en åpen søknad',
'cv-svar-0505': 'Levert en åpen søknad ved personlig oppmøte',
'cv-svar-0506': 'Tilbudt stilling uten å ha søkt',
'cv-svar-0507': 'Lite eller ingen erfaring',
'du-far-folgende-svar': 'Valgalternativer',
'ettvalg': 'Du må velge ett svaralternativ.',
'feilmelding-mangler-svar': 'Du må svare på spørsmålet før du kan gå videre.',
'finn-spm-01': 'Hvor finner du jobber?',
'finn-spm-02': 'Hvor ser du etter jobber?',
'finn-spm-03': 'Søker du på flere type stillinger?',
'finn-spm-04': 'Hva slags ansettelse ser du etter?',
'finn-spm-05': 'Hvor fornøyd er du med måten du leter etter jobb på?',
'finn-svar-0101': 'Stillingssøk på nav.no eller finn.no',
'finn-svar-0104': 'Sjekker sosiale medier og nettsider til bedrifter',
'finn-svar-0107': 'Bruker nettverket mitt',
'finn-svar-0109': 'Gjennom bemanningsbyråer',
'finn-svar-0110': 'Kontakter arbeidsgivere direkte',
'finn-svar-0111': 'Andre steder',
'finn-svar-0201': 'I nærområdet mitt',
'finn-svar-0202': 'Under en times reisevei',
'finn-svar-0203': 'I flere fylker',
'finn-svar-0204': 'I hele landet',
'finn-svar-0205': 'I utlandet',
'finn-svar-0206': 'Ingen begrensninger',
'finn-svar-0301': 'Jeg søker bare på én type stillinger',
'finn-svar-0302': 'Jeg søker på litt forskjellig',
'finn-svar-0303': 'Jeg søker på alt jeg kommer over',
'finn-svar-0401': 'Fast',
'finn-svar-0402': 'Vikariat',
'finn-svar-0403': 'Midlertidig',
'finn-svar-0404': 'Tilkallingshjelp',
'finn-svar-0405': 'Heltid',
'finn-svar-0406': 'Deltid',
'finn-svar-0501': '1',
'finn-svar-0502': '2',
'finn-svar-0503': '3',
'finn-svar-0504': '4',
'finn-svar-0505': '5',
'flervalg': 'Du kan velge flere svaralternativer.',
'forrige-knapp-start': 'Tilbake til start',
'forrige-knapp': 'Tilbake',
'fortsett-knapp': 'Neste',
'intervju-spm-01': 'Hvor trygg er du i en intervjusituasjon?',
'intervju-spm-02': 'Hvordan forbereder du deg til intervju?',
'intervju-spm-03': 'Hvor godt pleier du å gjøre det på intervju?',
'intervju-spm-04': 'Hva har du gjort for å øke sjansene dine for å få en jobb?',
'intervju-spm-05': 'Hvordan vurderer du din egen innsats som jobbsøker?',
'intervju-svar-0101': '1',
'intervju-svar-0102': '2',
'intervju-svar-0103': '3',
'intervju-svar-0104': '4',
'intervju-svar-0105': '5',
'intervju-svar-0201': 'Forbereder meg ikke, tar det som det kommer',
'intervju-svar-0202': 'Jeg vet ikke hvordan man forbereder seg',
'intervju-svar-0203': 'Tenker gjennom spørsmål jeg kan stille til arbeidsgiveren',
'intervju-svar-0204': 'Tenker igjennom hvordan jeg skal oppføre meg',
'intervju-svar-0205': 'Leser om bedriften på forhånd',
'intervju-svar-0206': 'Leser utlysningsteksten og søknaden på nytt',
'intervju-svar-0207': 'Øver på å presentere meg selv',
'intervju-svar-0301': '1',
'intervju-svar-0302': '2',
'intervju-svar-0303': '3',
'intervju-svar-0304': '4',
'intervju-svar-0305': '5',
'intervju-svar-0401': 'Tatt interessetester',
'intervju-svar-0402': 'Benyttet meg av nettverket mitt',
'intervju-svar-0403': 'Gått på jobbsøkerkurs',
'intervju-svar-0404': 'Søkt jobb i flere bransjer',
'intervju-svar-0405': 'Tatt kurs eller etterutdanning',
'intervju-svar-0406': 'Søkt jobber andre steder i landet',
'intervju-svar-0407': 'Engasjert meg i frivillig arbeid',
'intervju-svar-0408': 'Annet',
'intervju-svar-0409': 'Ikke noe spesielt',
'intervju-svar-0501': 'Har ikke begynt å søke jobber ennå',
'intervju-svar-0502': 'Ikke fornøyd med egen innsats',
'intervju-svar-0503': 'Kan gjøre en større innsats',
'intervju-svar-0504': 'Synes jeg har gjort en brukbar jobb',
'intervju-svar-0505': 'Er veldig fornøyd med egen innsats',
'loader-tekst': 'Takk, her er våre jobbsøkertips til deg!',
'mellomtekst-spm-cv-04': 'Velg på en skala fra 1 til 5, hvor 1 er "liten" og 5 er "stor".',
'mellomtekst-spm-finn-05': 'Velg på en skala fra 1 til 5 hvor 1 er "misfornøyd" og 5 er "fornøyd".',
'mellomtekst-spm-intervju-01': 'Velg på en skala fra 1 til 5 hvor 1 er "utrygg" og 5 er "trygg".',
'send-inn': 'Se resultatet',
'siste-sporsmal-logg-inn-lagringsmelding': 'Oi, du ble dessverre logget ut fordi du har vært logget inn litt for lenge, men vi vil gjerne ha deg tilbake! Logg inn på nytt for å se resultatene dine.',
'skala': 'Velg på en skala fra 1 til 5 hvor 1 er "dårlig" og 5 er "godt".',
'soke-spm-01': 'Hvor mange stillinger har du søkt siste året?',
'soke-spm-02': 'Hvor mange ganger har du blitt innkalt til intervju det siste året?',
'soke-spm-03': 'Hvor mange stillinger har du fått tilbud om det siste året?',
'soke-spm-04': 'Tilpasser du søknaden til hver enkelt stillingsannonse?',
'soke-svar-0101': 'Ingen',
'soke-svar-0102': '1 til 5',
'soke-svar-0103': '6 til 20',
'soke-svar-0104': '21 til 50',
'soke-svar-0105': '50+',
'soke-svar-0201': 'Ingen',
'soke-svar-0202': 'Noen få',
'soke-svar-0203': 'Flere',
'soke-svar-0301': 'Ingen',
'soke-svar-0302': 'Noen få',
'soke-svar-0303': 'Flere',
'soke-svar-0401': 'Sender samme søknad til alle',
'soke-svar-0402': 'Gjør noen tilpasninger',
'soke-svar-0403': 'Skreddersyr alle søknader',
'start-knapp': 'Start',
'startside-image-alt': 'Dame holder en mobiltelefon i hånden for å vise at tjenesten er tilpasset mobil. Illustrasjon.',
'startside-ingress': 'Bruk fem minutter på noen spørsmål og få jobbsøkertips.',
'startside-se-resultater-knapp': 'Se tidligere resultat',
'startside-tittel': 'Hvordan søker du på jobber?',
'startside-underoppfolging': 'Resultatene dine vil bli lagret automatisk og delt med NAV. Det er fordi du får oppfølging fra NAV.',
'forberedt-ikke-intervju': 'Vær forberedt på at du kan søke mange jobber uten å bli kalt inn til intervju. Jobb godt med CV-en og søknaden, det øker sjansene dine betraktelig.',
'intervju-fokus': 'Husk at hvis du har blitt kalt inn til et intervju, mener arbeidsgiveren at du er kvalifisert for jobben. I intervjuet bør du fokusere på motivasjon og hva du kan bidra med i bedriften.',
'intervju-hvorfor-deg': 'Arbeidsgiveren er opptatt av å høre om hvem du er og hva du kan tilføre bedriften. Forbered deg på å snakke om hvorfor akkurat du bør få jobben.',
'intervju-trygg': 'Når du er godt forberedt til intervjuet føler du deg tryggere og kan framstå som den du ønsker - den beste versjonen av deg selv! Øv deg på intervjusituasjonen.',
'nettverk': 'Husk at nettverket ditt er mer enn bare dine nærmeste venner og familie. Det kan være tidligere arbeidsgivere, lærere, naboer og bekjente.',
'oversikt-soknader': 'Logger du inn på Ditt NAV, kan du legge inn en stilling du har søkt på i din egen digitale aktivitetsplan.',
'passer-flere-jobber': 'Du trenger ikke nødvendigvis å fylle alle kravene i stillingsannonsen, de færreste gjør det. Kanskje passer bakgrunnen din til flere jobber enn du tror?',
'registrer-CV': 'Hvis du registrerer CV-en din flere steder, er det større sjanse for at en arbeidsgiver vil finne deg.',
'sok-utenfor-hjemsted': 'Har du vurdert å søke jobb utenfor hjemstedet ditt? Det kan øke mulighetene dine for å komme raskere i jobb.',
'soknad-svar-paa-annonsen': 'Husk å tilpasse søknaden til jobben du søker på. Søknaden skal være et direkte svar på stillingsannonsen.',
'tilpass-cv': 'Husk å tilpasse CV-en etter hva slags jobb du søker. Du bør fremheve det som er relevant for stillingen.',
'tips-standard-overskrift': 'VÅRT TIPS TIL DEG',
'vikariat-deltid': 'Et vikariat eller deltidsjobb kan føre til fast arbeid. Det er lettere å få en ny jobb når du allerede er i arbeid.'
 }, 'en': {} };