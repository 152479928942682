import * as React from 'react';
import { Dispatch } from './types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { nullStillStore } from './ducks/side-duck';
import { Sidetype } from './utils/konstanter';
import alleSporsmal from './sporsmal/sporsmal-alle';
import { clearLocalStorage } from './utils/persistentReducer';

interface DispatchProps {
    doNullStillStore: () => void;
}

export class RedirectForsteSporsmal extends React.Component<DispatchProps> {
    // eslint-disable-next-line
    constructor(props: DispatchProps) {
        super(props);
    }

    componentDidMount() {
        clearLocalStorage();
        this.props.doNullStillStore();
    }

    render() {
        const startSpmUrl = '/' + Sidetype.KARTLEGGING + '/' + alleSporsmal[0].id;

        return <Redirect to={startSpmUrl}/>;
    }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doNullStillStore: () => dispatch(nullStillStore())
});

export default connect(null, mapDispatchToProps)(RedirectForsteSporsmal);
