import raadLogikk from './raad-config-alternativer';
import { BrukerRaad, Raad, UtledetRaadModell } from './raad-modell';
import { BesvartSporsmal } from '../../ducks/sporsmal-duck';
import { fasteRaadFraModell, variableRaadFraModell } from './raad-config';
import alleTekster from '../../tekster/alle-tekster';
import { Tekster, utledTekster } from '../../providers/intl-provider';

export function diskvalifiserFasteRaad(avgitteSvar: string[], fasteRaad: UtledetRaadModell[]) {
    return fasteRaad.filter(raad => {
        if (raadLogikk[raad.ref]) {
            if (raadLogikk[raad.ref].alternativ.some((alternativ: string) => avgitteSvar.includes(alternativ))) {
                return false;
            }
        }
        return true;
    });
}

export function leggTilVariableRaad(avgitteSvar: string[], variableRaad: UtledetRaadModell[]) {
    return variableRaad.filter(raad => {
        if (raadLogikk[raad.ref]) {
            if (raadLogikk[raad.ref].alternativ.some((alternativ: string) => avgitteSvar.includes(alternativ))) {
                return true;
            }
        }
        return false;
    });
}

export function getAlleSvarteAlternativer(besvarteSporsmal: BesvartSporsmal[]) {
    return besvarteSporsmal.reduce(
        (acc: string[], besvartSpm: BesvartSporsmal) => acc.concat(besvartSpm.svar),
        []
    );
}

export function utledBrukerRaad(utledetRaad: UtledetRaadModell[], raad: Raad[], tekster: Tekster): BrukerRaad[] {
    return utledetRaad.map((ul: UtledetRaadModell) => {
        const riktigRaad = raad.find(r => {
            return r.refid === ul.refid;
        })!;
        return {
            id: ul.refid,
            tittel: riktigRaad ? riktigRaad.tittel : '',
            ingress: tekster[ul.refid] ? tekster[ul.refid] : riktigRaad ? riktigRaad.ingress : '',
            aktiviteter: riktigRaad ? riktigRaad.aktiviteter : [],
        };
    });
}

export function genererRaad(avgitteSvar: string[], raad: Raad[]): BrukerRaad[] {
    const fasteRaadJustert = diskvalifiserFasteRaad(avgitteSvar, fasteRaadFraModell);

    const variableRaadJustert = leggTilVariableRaad(avgitteSvar, variableRaadFraModell);

    const resultat = fasteRaadJustert
        .concat(variableRaadJustert)
        .sort((temaA, temaB) => temaA.prioritet - temaB.prioritet);

    return utledBrukerRaad(resultat, raad, utledTekster(alleTekster.nb));
}
