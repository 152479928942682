import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../types';
import { AppState } from '../ducks/reducer';
import { ApiState, hentNyesteBesvarelse, settApiOK } from '../ducks/api-duck';
import { RouteComponentProps, withRouter } from 'react-router';
import Datalaster from '../api/datalaster';

interface OwnProps {
    children: React.ReactElement<any>; // tslint:disable-line:no-any
}

interface StateProps {
    besvarelseApi: ApiState;
    underOppfolging: boolean;
}

interface DispatchProps {
    doHentNyesteBesvarelse: () => void;
    doSettApiOK: () => void;
}

type BesvarelseProviderProps = OwnProps & StateProps & DispatchProps & RouteComponentProps<any>; // tslint:disable-line

class BesvarelseProvider extends React.Component<BesvarelseProviderProps> {
    // eslint-disable-next-line
    constructor(props: BesvarelseProviderProps) {
        super(props);
    }

    componentDidMount() {
        if (this.props.underOppfolging) {
            this.props.doHentNyesteBesvarelse();
        } else {
            this.props.doSettApiOK();
        }
    }

    render() {
        return (
            <Datalaster avhengigheter={[this.props.besvarelseApi]}>
                {this.props.children}
            </Datalaster>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    besvarelseApi: state.api,
    underOppfolging: state.oppfolging.underOppfolging,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doHentNyesteBesvarelse: () => hentNyesteBesvarelse()(dispatch),
    doSettApiOK: () => dispatch(settApiOK()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BesvarelseProvider));
