import 'babel-polyfill';

import 'nav-frontend-lukknapp-style';
import 'nav-frontend-knapper-style';
import 'nav-frontend-lenker-style';
import 'nav-frontend-core/less/core.less';
import 'nav-frontend-modal-style';

import './index.less';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './app';
import { unregister } from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

unregister();
