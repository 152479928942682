import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppState } from './ducks/reducer';

const visTeksterQueryString = '?vistekster=true';

interface OwnProps {
    children: React.ReactElement<any>; // tslint:disable-line:no-any
    url: string;
    state?: { [key: string]: boolean };
    className?: string;
    key?: string;
    onClick?: () => void;
}

interface StateProps {
    skalViseTekstnokler: boolean;
}

type LinkProps = OwnProps & StateProps;

const LinkMedQueryParams = (props: LinkProps) => {
    const { url, state, className, key, onClick, skalViseTekstnokler } = props;
    const query = skalViseTekstnokler ? visTeksterQueryString : '';
    const linkConfig = { pathname: url, search: query, state: state };
    return (
        <Link
            to={linkConfig}
            className={className}
            key={key}
            onClick={onClick}
        >
            {props.children}
        </Link>
    );
}

const mapStateToProps = (state: AppState): StateProps => ({
    skalViseTekstnokler: state.tekstnokkel.skalViseTekstnokler,
});

export default connect(mapStateToProps)(LinkMedQueryParams);
