import {
    ActionType, GenererOppsummeringAction, GenererKulepunkterAction, Handling
} from '../actions';
import { BesvartSporsmal } from './sporsmal-duck';
import { genererPositiveKulepunkter, Kulepunkt } from '../resultat/kulepunkt/kulepunkter-generering';
import { genererKonstruktivOppsummering, Oppsummering } from '../resultat/oppsummering/oppsummering-generering';
import { KartleggingDto } from '../api/kartlegging-dto';

export interface TilbakemeldingState {
    kulepunkter: Kulepunkt[];
    oppsummering: Oppsummering;
}

export const initialState: TilbakemeldingState = {
    kulepunkter: [],
    oppsummering: { tekstId: ''}
};

//  Reducer
export default function reducer(
    state: TilbakemeldingState = initialState,
    action: Handling): TilbakemeldingState {
    switch (action.type) {
        case ActionType.GENERER_KULEPUNKTER:
            return { ...state, kulepunkter: genererPositiveKulepunkter(action.besvarteSporsmal)};
        case ActionType.NULL_STILL_STORE:
            return initialState;
        case ActionType.HENT_BESVARELSE_OK:
            return {
                ...state,
                kulepunkter: lagKulepunkter(action.lagretBesvarelse),
                oppsummering: lagOppsummering(action.lagretBesvarelse)
            };
        case ActionType.GENERER_OPPSUMMERING:
            return { ...state, oppsummering: genererKonstruktivOppsummering(action.besvarteSporsmal)};
        default:
            return state;
    }
}

function lagKulepunkter(lagretKartlegging: KartleggingDto): Kulepunkt[] {
    return lagretKartlegging && lagretKartlegging.kulepunkter.map(kulepunkt => ({
        tekstId: kulepunkt.kulepunktKey,
        prioritet: kulepunkt.kulepunktPrioritet
    }));
}

function lagOppsummering(lagretKartlegging: KartleggingDto): Oppsummering {
    const lagretOppsummeringKey =
        lagretKartlegging && lagretKartlegging.oppsummering !== null
        ? lagretKartlegging.oppsummeringKey
        : ''
    ;
    return {
        tekstId: lagretOppsummeringKey
    };
}

export function genererKulepunkter(besvarteSporsmal: BesvartSporsmal[]): GenererKulepunkterAction {
    return {
        type: ActionType.GENERER_KULEPUNKTER,
        besvarteSporsmal: besvarteSporsmal,
    };
}

export function genererOppsummering(besvarteSporsmal: BesvartSporsmal[]): GenererOppsummeringAction {
    return {
        type: ActionType.GENERER_OPPSUMMERING,
        besvarteSporsmal: besvarteSporsmal,
    };
}
