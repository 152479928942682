import * as React from 'react';
import { Ingress, Sidetittel } from 'nav-frontend-typografi';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import alleSporsmal from '../sporsmal/sporsmal-alle';
import { Sidetype } from '../utils/konstanter';
import { Dispatch } from '../types';
import { nullStillStore } from '../ducks/side-duck';
import { connect } from 'react-redux';
import VisibleIf from '../utils/visible-if';
import { AppState } from '../ducks/reducer';
import { ApiState, hentNyesteBesvarelse } from '../ducks/api-duck';
import BesvarelseProvider from '../providers/besvarelse-provider';
import { clearLocalStorage } from '../utils/persistentReducer';
import LinkMedQueryParams from '../link-med-query-params';
import { uniLogger } from '../metrikker/uni-logger';

const sporsmalImg = require('../ikoner/forside.svg');

interface StateProps {
    underOppfolging: boolean;
    harLagretInnevaerendeKartleggingIDatabasen: boolean;
    api: ApiState;
}

interface DispatchProps {
    doNullStillStore: () => void;
    doHentNyesteBesvarelse: () => void;
}

type StartsideProps = DispatchProps & StateProps;

class Startside extends React.Component<StartsideProps> {
    // eslint-disable-next-line
    constructor(props: StartsideProps) {
        super(props);
    }

    componentDidMount() {
        uniLogger('jobbsokerkompetanse.start.visning', {});
        const header = document.querySelector('.siteheader');
        if (header) {
            const side = document.querySelector('.startside');
            const hode = header.getBoundingClientRect().height;
            if (side) {
                side.setAttribute('style', 'height: calc(100vh - ' + hode + 'px)');
            }
            const innh = document.querySelector('.startside__innhold');
            if (innh) {
                innh.setAttribute('style', 'height: calc(70vh - ' + hode + 'px)');
            }
        }
        document.querySelector<HTMLElement>('.startside__knapperad .knapp')!.focus();
    }

    render() {
        const {doNullStillStore, harLagretInnevaerendeKartleggingIDatabasen} = this.props;

        const startSpmUrl = '/' + Sidetype.KARTLEGGING + '/' + alleSporsmal[0].id;
        const resultatsideUrl = '/' + Sidetype.RESULTAT;

        return (
            <div className="limit limit__startside">
                <div className="startside">
                    <div className="startside__bildewrap">
                        <FormattedMessage id="startside-image-alt">
                            {(tekst: string | React.ReactElement<string>) => (
                                <img
                                    src={sporsmalImg}
                                    alt={tekst as string}
                                    className="startside__bilde"
                                />
                            )}
                        </FormattedMessage>
                    </div>
                    <div className="startside__innhold">
                        <div className="startside__tekst">
                            <Sidetittel tag="h1" className="blokk-xs">
                                <FormattedHTMLMessage id="startside-tittel"/>
                            </Sidetittel>
                            <Ingress>
                                <FormattedHTMLMessage id="startside-ingress"/>
                            </Ingress>
                        </div>
                        <div className="startside__knapperad">
                            <LinkMedQueryParams
                                url= {startSpmUrl}
                                className="knapp knapp--hoved knapp--full"
                                onClick={() => {
                                    clearLocalStorage();
                                    doNullStillStore();
                                }}
                            >
                                <FormattedMessage id="start-knapp"/>
                            </LinkMedQueryParams>
                        </div>
                        <VisibleIf betingelse={harLagretInnevaerendeKartleggingIDatabasen}>
                            <div className="startside__knapperad resultatknapp">
                                <LinkMedQueryParams
                                    url={resultatsideUrl}
                                    className="knapp knapp--full"
                                >
                                    <FormattedMessage id="startside-se-resultater-knapp"/>
                                </LinkMedQueryParams>
                            </div>
                        </VisibleIf>
                        <div className="startside__footer">
                            <VisibleIf betingelse={this.props.underOppfolging}>
                                <p className="typo-etikett-liten">
                                    <FormattedMessage id="startside-underoppfolging"/>
                                </p>
                            </VisibleIf>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    underOppfolging: state.oppfolging.underOppfolging,
    harLagretInnevaerendeKartleggingIDatabasen: state.api.harLagretInnevaerendeKartleggingIDatabasen,
    api: state.api,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doNullStillStore: () => dispatch(nullStillStore()),
    doHentNyesteBesvarelse: () => hentNyesteBesvarelse()(dispatch)
});

const StartsideConnected = connect(mapStateToProps, mapDispatchToProps)(Startside);

export default function StartsideWrapper() {
    return (
        <BesvarelseProvider>
            <StartsideConnected/>
        </BesvarelseProvider>
    );
}
