import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../types';
import { Features, hentFeatures } from '../ducks/feature-duck';
import { AppState } from '../ducks/reducer';

interface OwnProps {
    children: React.ReactElement<any>; // tslint:disable-line:no-any
}

interface StateProps {
    features: Features;
}

interface DispatchProps {
    doHentFeatures: () => void;
}

type FeatureProviderProps = OwnProps & StateProps & DispatchProps;

class FeatureProvider extends React.Component<FeatureProviderProps> {
    // eslint-disable-next-line
    constructor(props: FeatureProviderProps) {
        super(props);
    }
    componentDidMount() {
        this.props.doHentFeatures();
    }
    render() {
        return this.props.children;
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    features: state.feature,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doHentFeatures: () => hentFeatures()(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureProvider);